import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from './component/Layout/layout'
import Home from './Pages/Home/home'
import Aboutus from './Pages/About-us/about-us'
import ChannelPartner from './Pages/Channel-partner/channel-partner'
import Contactus from './Pages/Contact-us/contact-us'
import Login from './Pages/Log-in/log-in'
import OtpScreen from './Pages/otp-screen/otp-screen'
import Profilepage from './Pages/Profile-page/profile-page';
import Eligibilitycheck from './Pages/Eligibility-check/eligibility-check';
import EMIoption from './Pages/EMI-option/emi-option';
import Subscriptionplan from './Pages/Subscription-plan/subscription-plan';
import Privacypolicy from './Pages/privacy-policy';
import TreamsCondition from './Pages/treams-condition';
import Personalloan from './Pages/Personal-loan/personal-loan';
import Cancelationrefund from './Pages/Cancelation-refund/Cancelation-refund';
import Disclaimer from './Pages/Disclaimer/disclaimer';
import Businessloan from './Pages/Business-loan/business-loan';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce, ToastContainer} from 'react-toastify';

const App = () => {

  return (

    <>

    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/about-us' element={<Aboutus/>} />
          <Route path='/channel-partner' element={<ChannelPartner/>} />
          <Route path='/contact-us' element={<Contactus/>} />
          <Route path='/log-in' element={<Login/>} />
          <Route path='/verify' element={<OtpScreen/>} />
          <Route path='/profile-page' element={<Profilepage/>} />
          <Route path='/eligibility-check' element={<Eligibilitycheck/>} />
          <Route path='/emi-option' element={<EMIoption/>} />
          <Route path='/subscription-plan' element={<Subscriptionplan/>} />
          <Route path='/privacy-policy' element={<Privacypolicy/>} />
          <Route path='/terms-condition' element={<TreamsCondition/>} />
          <Route path='/personal-loan' element={<Personalloan/>} />
          <Route path='/business-loan' element={<Businessloan/>} />
          <Route path='/refund-policy' element={<Cancelationrefund/>} />
          <Route path='/disclaimer' element={<Disclaimer/>} />
        </Route>
      </Routes>
    </Router>
    <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition={Bounce}
      />
    </>
    

  )
}

export default App

