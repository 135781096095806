import React from 'react'
import LoginImage from './imgs/log-in-page-image.svg'
import RighttickIcon from './imgs/right-tick-icon.svg'

const Commonloginsection = () => {
    return (
        <div className='main-containerr'>
            <div className=''>
                <div className='flex flex-col w-[100%] rounded-[30px] log-in-screen-bg-effect'>
                    <div className='log-in-image'>
                        <img src={LoginImage} alt='Log in Page Image' className='w-[100%] xs:hidden sm:hidden'></img>
                    </div>
                    <div className='px-[30px] py-[20px]'>
                        <p className='text-[#F2BD70] text-[15px] font-[500]'>In Just Few Minutes!</p>
                        <p className='text-[38px] font-[900] leading-[40px] text-[#fff]'>Avail Rs.5,00,000/- Personal Loan</p>
                        <p className='text-[14px] font-[500] mt-[20px] text-[#fff]'>Whether it’s for education, a wedding, home renovation, or any personal goal, we’ve got you covered.</p>
                        <div>
                            <div className='flex gap-[10px] items-center mt-[20px]'>
                                <img src={RighttickIcon} alt='Righttick Icon'></img>
                                <p className='text-[16px] font-[500] text-[#F2BD70]'>Fast Disbursement</p>
                            </div>
                            <div className='flex gap-[10px] items-center mt-[10px]'>
                                <img src={RighttickIcon} alt='Righttick Icon'></img>
                                <p className='text-[16px] font-[500] text-[#F2BD70]'>Installment Per Lakh - Rs.63/Day</p>
                            </div>
                            <div className='flex gap-[10px] items-center mt-[10px]'>
                                <img src={RighttickIcon} alt='Righttick Icon'></img>
                                <p className='text-[16px] font-[500] text-[#F2BD70]'>Offer from top NBFCs</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Commonloginsection