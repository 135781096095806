import React from 'react'
import { FaChevronRight } from 'react-icons/fa'

const Contactussection = () => {
    return (
        <div className='main-contanerr'>
            <div>
                <div>
                    <div>
                        <div className='contact-info-main w-[90%] mx-auto rounded-[30px] mt-[100px]'>
                            <div className='contact-us-main  py-[30px] px-[50px] xs:px-[15px] sm:px-[10px] rounded-[30px]'>
                                <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] leading-[50px] text-[#fff]'>Have more questions?</p>
                                <p className='text-[18px] font-[500] text-[#fff] mt-[10px]'>We got you! Get quick resolutions to your concerns</p>
                                <div className='flex  gap-[20px] items-center mt-[80px]'>
                                    <a href="/contact-us"><button className='flex bg-[#DC9731] px-[20px] py-[15px] rounded-[30px] text-[16px] font-[500] text-[#fff] items-center gap-[10px]'>Contact Us <FaChevronRight /></button></a>
                                    <a href='/about-us'><p className='text-[#F2BD70] text-[16px] font-[500] mb-[0px]'>Learn More About</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contactussection