import React from 'react'
import { IoCall, IoPerson } from 'react-icons/io5'
import MoneyBag from './../../imgs/money-bag.svg'

const EMIoption = () => {
    return (
        <div className='main-contanerr'>
            <div>
                <div>
                    <div>
                        <div>
                            <div className='flex justify-between xs:block sm:block xs:gap-[10px] sm:gap-[10px] items-center px-[50px] xs:px-[20px] sm:px-[20px] border border-[#ECECEC] eligible-loan-amount-main py-[30px]'>
                                <div>
                                    <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] text-[#323232] leading-[50px] xs:text-[40px] sm:text-[40px]'>Congrats, Shah Rajesh!</p>
                                    <p className='text-[18px] font-[500] text-[#323232] mt-[10px]'>You pre-approved offer is successfully confirmed. Process your loan offer now.</p>
                                </div>
                                <div className='xs:mt-[10px] sm:mt-[10px]'>
                                    <p className='text-[24px] font-[500] text-[#45AE4C]'>Eligible Loan Amount</p>
                                    <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[700] text-[#323232]'>₹ 4,54,880</p>
                                </div>
                            </div>
                            <div>
                                <div className='w-[90%] mx-auto mt-[50px] '>
                                    <div className='flex gap-[30px] xs:block sm:block'>
                                        <div className='w-[30%] xs:w-[95%] sm:w-[95%] xs:mx-auto sm:mx-auto px-[30px] py-[30px] rounded-[30px] check-eligible-main'>
                                            <p className='text-[14px] font-[500] text-[#1100FF]'>Step 3 to 4</p>
                                            <p className='text-[32px] font-[900] text-[#323232] mt-[10px]'>Check Eligibility</p>
                                            <hr className='bg-[#000] mt-[20px]' />
                                            <div className='flex gap-[10px] items-center mt-[20px]'>
                                                <p><IoPerson className='text-[35px] text-[#DC9731] ' /></p>
                                                <div>
                                                    <p className='text-[14px] font-[500] text-[#323232]'>Full Name:</p>
                                                    <span className='text-[20px] font-[500] text-[#BD7000]'>Shah Rajesh Maheshbhai</span>
                                                </div>
                                            </div>
                                            <div className='flex gap-[10px] items-center mt-[10px]'>
                                                <p><IoCall className='text-[35px] text-[#DC9731] ' /></p>
                                                <div>
                                                    <p className='text-[14px] font-[500] text-[#323232]'>Mobile Number:</p>
                                                    <span className='text-[20px] font-[500] text-[#BD7000]'>+91 98999 32323</span>
                                                </div>
                                            </div>
                                            <div className='flex gap-[10px] items-center mt-[10px]'>
                                                <img src={MoneyBag} alt='Money Bag Icon'></img>
                                                <div>
                                                    <p className='text-[14px] font-[500] text-[#323232]'>Loan Amount:</p>
                                                    <span className='text-[20px] font-[500] text-[#BD7000]'>₹ 80,000</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-[65%] xs:w-[95%] sm:w-[95%] xs:mx-auto sm:mx-auto xs:mt-[20px] sm:mt-[20px] xs:px-[15px] sm:px-[15px] bg-[#F3F3F3] px-[30px] py-[30px] rounded-[30px]'>
                                            <div>
                                                <p className='text-[20px] font-[700] text-[#323232]'>Choose Your Suitable EMI Option for <span>₹ 80,000</span> Loan</p>
                                            </div>
                                            <div className='grid grid-cols-2 xs:grid-cols-1 sm:grid-cols-1 items-center mt-[30px]'>
                                                <div>
                                                    <label class="radio-button rounded-[8px] bg-[#fff]">
                                                        <input type="radio" name="example-radio" value="option1" />
                                                        <span class="radio"></span>
                                                        <span className='text-[16px] font-[500] text-[#525252]'>12 Month - EMI: <span>₹ 7,466.00</span></span>
                                                    </label>
                                                    <label class="radio-button rounded-[8px] bg-[#fff]">
                                                        <input type="radio" name="example-radio" value="option2" />
                                                        <span class="radio"></span>
                                                        <span className='text-[16px] font-[500] text-[#525252]'>24 Month - EMI: <span>₹ 3,733.00</span></span>
                                                    </label>
                                                    <label class="radio-button rounded-[8px] bg-[#fff]">
                                                        <input type="radio" name="example-radio" value="option2" />
                                                        <span class="radio"></span>
                                                        <span className='text-[16px] font-[500] text-[#525252]'>36 Month - EMI: <span>₹ 2,488.00</span></span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label class="radio-button rounded-[8px] bg-[#fff]">
                                                        <input type="radio" name="example-radio" value="option1" />
                                                        <span class="radio"></span>
                                                        <span className='text-[16px] font-[500] text-[#525252]'>48 Month - EMI: <span>₹ 1,666.00</span></span>
                                                    </label>
                                                    <label class="radio-button rounded-[8px] bg-[#fff]">
                                                        <input type="radio" name="example-radio" value="option2" />
                                                        <span class="radio"></span>
                                                        <span className='text-[16px] font-[500] text-[#525252]'>60 Month - EMI: <span>₹ 1,493.00</span></span>
                                                    </label>
                                                    <label class="radio-button rounded-[8px] bg-[#fff]">
                                                        <input type="radio" name="example-radio" value="option2" />
                                                        <span class="radio"></span>
                                                        <span className='text-[16px] font-[500] text-[#525252]'>72 Month - EMI: <span>₹ 1,244.00</span></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <a href='/subscription-plan'><button type='submit' className='text-[#563200] text-[16px] bg-[#F2BD70] rounded-[40px] px-[30px] mt-[20px] py-[10px] font-[500]'>Get Offer</button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EMIoption