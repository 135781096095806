import React from 'react'
import Contactussection from '../contact-us-section'

const Cancelationrefund = () => {
    return (
        <div className='main-contanerr'>
            <div>
                <div>
                    <div>
                        <div className='w-[90%] mx-auto mt-[50px]'>
                            <p className='text-[20px] font-[500] text-[#525252]'>We always strive for excellent customer satisfaction – and it reflects in our customer-oriented Refund Policy!</p>
                            <div>
                                <p className='text-[#525252] text-[20px] font-[700] mt-[20px]'>Under what circumstances is the Karmved Bharat Subscription Plan fee refundable?</p>
                                <p className='text-[18px] font-[500] text-[#525252] mt-[15px]'>A customer can be eligible for a refund if an e-mail requesting a refund is done by the customer (with the registered email id) to <a href="mailto:info@karmvedbharat.com">info@karmvedbharat.com</a> within 30 days of purchasing the Karmved Bharat Subscription Plan/Karmved Capital Plan. The refund will be received by the customer (if eligible for a refund) within 24 to 48 hours of receiving the mail.</p>
                                <p className='text-[18px] font-[500] text-[#525252] mt-[15px]'>There are certain areas/locations in which our company does not provide its services. If any person who has bought our Karmved Bharat Subscription Plan/Karmved Capital and belongs to such areas/locations can apply for a refund within 30 days of purchasing the Karmved Bharat Subscription Plan/Karmved Capital Plan. To know the areas where our company does not provide services, please call on +91 84478 96394.</p>
                                <p className='text-[18px] font-[500] text-[#525252] mt-[15px]'>A customer might get a refund of the Karmved Bharat Subscription Plan/Karmved Capital Plan fee if the company hasn’t started the ‘Customer Service Initiation’ (explained below) within 48 hours of the payment and a request for the same has been raised through Raise A Request within 30 days of the payment.</p>
                                <p className='text-[18px] font-[500] text-[#525252] mt-[15px]'> If the customer is unable to communicate with the company in English, Hindi, or Gujarati, they can apply for a refund within 30 days of purchasing the Karmved Bharat Subscription Plan/Karmved Capital Plan.</p>
                                <p className='text-[18px] font-[500] text-[#525252] mt-[15px]'>Once the refund request is approved,The Refunded Amount will be processed and credited within 2 Days to the original mode of payment.</p>
                                <p className='text-[#525252] text-[20px] font-[700] mt-[20px]'>What does Customer Service Initiation mean?</p>
                                <p className='text-[18px] font-[500] text-[#525252] mt-[15px]'>It is the first process done by the company that includes calling the customer for verification within 24-48 hours of the Karmved Bharat Subscription Plan/Karmved Capital Plan payment. Even if the customer does not respond to the verification call, the Customer Service Initiation is considered as started and the update has been shown in the customer portal.</p>
                                <p className='text-[18px] font-[500] text-[#525252] mt-[15px]'>If you have any questions about our Cancellation and Refund Policy, please contact us between 10 am - 5 pm (only business days):</p>
                                <ul className='list-disc list-inside mt-[20px]'>
                                    <li className='text-[18px] font-[500] text-[#525252] mt-[15px]'>By email: <a href="mailto:info@karmvedbharat.com">info@karmvedbharat.com</a></li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <Contactussection />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cancelationrefund