import React, { useState } from 'react'
import { IoCall, IoPerson } from 'react-icons/io5'
import MoneyBag from './../../imgs/money-bag.svg'

const Eligibilitycheck = () => {
    const [selectedValue, setSelectedValue] = useState('');
    const [monthlyIncome, setMonthlyIncome] = useState('');
    const [EMI, setEmi] = useState('');
    const [loanpurpose, setLoanpurpose] = useState('');
    const [city,setCity] = useState('');
    const [state,setState] = useState('');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);  // Update state with selected value
    };
    const handleIncome = (e) => {
        setMonthlyIncome(e.target.value)
    }
    const handleEMI = (e) => {
        setEmi(e.target.value)
    }
    const handleloanPurpose = (e) => {
        setLoanpurpose(e.target.value)
    }
    const handlecity = (e) => {
        setCity(e.target.value)
    }
    const handlestate = (e) => {
        setState(e.target.value)
    }


    return (
        <div className='main-contanerr'>
            <div>
                <div>
                    <div>
                        <div className='bg-[#FFFCF9] px-[50px] border border-[#ECECEC] py-[30px]'>
                            <p className='text-[48px] font-[900] text-[#323232] leading-[50px] xs:text-[40px] sm:text-[40px]'>Personal Loan application</p>
                            <p className='text-[18px] font-[500] text-[#323232] mt-[10px]'>Just a few more details to get pre-approved loan offer from our partnered NBFCs</p>
                        </div>
                        <div>
                            <div className='w-[90%] mx-auto mt-[50px] '>
                                <div className='flex gap-[30px] xs:block sm:block'>
                                    <div className='w-[30%] xs:w-[90%] sm:w-[90%] xs:mx-auto sm:mx-auto px-[30px] py-[30px] rounded-[30px] check-eligible-main'>
                                        <p className='text-[14px] font-[500] text-[#1100FF]'>Step 2 to 4</p>
                                        <p className='text-[32px] font-[900] text-[#323232] mt-[10px]'>Check Eligibility</p>
                                        <hr className='bg-[#000] mt-[20px]' />
                                        <div className='flex gap-[10px] items-center mt-[20px]'>
                                            <p><IoPerson className='text-[35px] text-[#DC9731] ' /></p>
                                            <div>
                                                <p className='text-[14px] font-[500] text-[#323232]'>Full Name:</p>
                                                <span className='text-[20px] font-[500] text-[#BD7000]'>Shah Rajesh Maheshbhai</span>
                                            </div>
                                        </div>
                                        <div className='flex gap-[10px] items-center mt-[10px]'>
                                            <p><IoCall className='text-[35px] text-[#DC9731] ' /></p>
                                            <div>
                                                <p className='text-[14px] font-[500] text-[#323232]'>Mobile Number:</p>
                                                <span className='text-[20px] font-[500] text-[#BD7000]'>+91 98999 32323</span>
                                            </div>
                                        </div>
                                        <div className='flex gap-[10px] items-center mt-[10px]'>
                                            <img src={MoneyBag} alt='Money Bag Icon'></img>
                                            <div>
                                                <p className='text-[14px] font-[500] text-[#323232]'>Loan Amount:</p>
                                                <span className='text-[20px] font-[500] text-[#BD7000]'>₹ 80,000</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-[65%] xs:w-[90%] sm:w-[90%] xs:mx-auto sm:mx-auto xs:mt-[20px] sm:mt-[20px] xs:px-[15px] sm:px-[15px] bg-[#F3F3F3] px-[30px] py-[30px] rounded-[30px]'>

                                        <form>
                                            <div className='grid grid-cols-2 xs:grid-cols-1 sm:grid-cols-1 gap-[20px]'>
                                                <div>
                                                    <label className='text-[#49454F] text-[16px] font-[500]'>CIBIL Score</label><br />
                                                    <select className='px-[20px] py-[10px] rounded-[8px] border w-[100%] mt-[10px] xs:w-[100%] sm:w-[100%] input-box-shadow' value={selectedValue} onChange={handleChange}>
                                                        {/* <option selected>Select CIBIL Score</option> */}
                                                        <option value="below 750+">Below 750+</option>
                                                        <option value="below 850+">Below 850+</option>
                                                        <option value="below 950+">Below 950+</option>
                                                        <option value="below 1050+">Below 1050+</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <label className='text-[#49454F] text-[16px] font-[500]'>Monthly Income</label><br />
                                                    <input type='text' placeholder='Enter your monthly income' className='px-[20px] py-[10px] rounded-[8px] border w-[100%] xs:w-[100%] sm:w-[100%] input-box-shadow mt-[10px]' value={monthlyIncome} onChange={handleIncome}></input>
                                                </div>
                                            </div>
                                            <div className='grid grid-cols-2 xs:grid-cols-1 sm:grid-cols-1 gap-[20px] mt-[20px]'>
                                                <div>
                                                    <label className='text-[#49454F] text-[16px] font-[500]'>Current Monthly EMI</label><br />
                                                    <select className='px-[20px] py-[10px] rounded-[8px] border w-[100%] mt-[10px] xs:w-[100%] sm:w-[100%] input-box-shadow' value={EMI} onChange={handleEMI}> 
                                                        <option value="1 Month">1</option>
                                                        <option value="2 Month">2</option>
                                                        <option value="3 Month">3</option>
                                                        <option value="4 Month">4</option>
                                                        <option value="5 Month">5</option>
                                                        <option value="6 Month">6</option>
                                                        <option value="7 Month">7</option>
                                                        <option value="8 Month">8</option>
                                                        <option value="9 Month">9</option>
                                                        <option value="10 Month">10</option>
                                                        <option value="11 Month">11</option>
                                                        <option value="12 Month">12</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <label className='text-[#49454F] text-[16px] font-[500]'>Select Loan Perpose</label><br />
                                                    <select className='px-[20px] py-[10px] rounded-[8px] border w-[100%] mt-[10px] xs:w-[100%] sm:w-[100%] input-box-shadow' value={loanpurpose} onChange={handleloanPurpose}> 
                                                        <option value="Personal">Personal</option>
                                                        <option value="Business">Business</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='grid grid-cols-2 xs:grid-cols-1 sm:grid-cols-1 gap-[20px] mt-[20px]'>
                                                <div>
                                                    <label className='text-[#49454F] text-[16px] font-[500]'>City</label><br />
                                                    <input type='text' placeholder='Ex. Surat' className='px-[20px] py-[10px] rounded-[8px] border w-[100%] xs:w-[100%] sm:w-[100%] input-box-shadow mt-[10px]' value={city} onChange={handlecity}></input>
                                                </div>
                                                <div>
                                                    <label className='text-[#49454F] text-[16px] font-[500]'>State</label><br />
                                                    <input type='text' placeholder='Ex. Gujarat' className='px-[20px] py-[10px] rounded-[8px] border w-[100%] xs:w-[100%] sm:w-[100%] input-box-shadow mt-[10px]' value={state} onChange={handlestate}></input>
                                                </div>
                                            </div>
                                            <a href='/emi-option'><button type='submit' className='text-[#563200] text-[16px] bg-[#F2BD70] rounded-[40px] px-[30px] mt-[20px] py-[10px] font-[500]'>Check Eligibility</button></a>
                                        </form>
                                        {/* <p>Selected Value: {selectedValue}</p>
                                        <p>Selected Value: {monthlyIncome}</p>
                                        <p>Selected Value: {EMI}</p>
                                        <p>Selected Value: {loanpurpose}</p>
                                        <p>Selected Value: {city}</p>
                                        <p>Selected Value: {state}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Eligibilitycheck