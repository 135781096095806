import React, { useState } from 'react'
import { HiBadgeCheck } from "react-icons/hi";
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import Firstnumberimg from './../../imgs/first-number-img.svg'
import Secondnumberimg from './../../imgs/second-number-img.svg'
import Thirdnumberimg from './../../imgs/third-number-img.svg'
import Fournumberimg from './../../imgs/fourth-number-img.svg'
import DocumentIcon from './../../imgs/document_icon.svg'
import BankIcon from './../../imgs/bank_icon.svg'
import Usericon from './../../imgs/user-icon.svg'
import Customer1 from './../../imgs/karmved-bharat-customer1.svg'
import Customer2 from './../../imgs/karmved-bharat-customer2.svg'
import TataCapital from './../../imgs/tata_capital_logo.svg'
import BajajFinance from './../../imgs/bajaj_finance_logo.svg'
import FibeLogo from './../../imgs/fibe_logo.svg'
import MuthootFinance from './../../imgs/muthoot_finance_logo.svg'
import HeroFinacorp from './../../imgs/hero_fincorp.svg'
import LTFinance from './../../imgs/lnt_finance.svg'
import StartrdChart from './../../imgs/Standard_Chartered.svg'
import HDFCLoan from './../../imgs/hdfc_home_loan.svg'
import Prefr from './../../imgs/prefr_logo.svg'

import KarmvedBharatVector from './../../imgs/karmved-bharat-vector.svg'


import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination, Autoplay } from 'swiper/modules';
import { GiRoundStar } from 'react-icons/gi';

import FAQ from './../../faq'

import Contactussection from '../contact-us-section';

const Businessloan = () => {

    const [value, setValue] = useState(100000);
    const [tenure, setTenure] = useState(12);
    const [intrestrate, setIntrestrate] = useState(9);


    const handleChange = (e) => {
        setValue(e.target.value);
    };
    const handleTenureChange = (e) => {
        setTenure(e.target.value);
    };
    const handleIntrest = (e) => {
        setIntrestrate(e.target.value)
    }

    const calculateEMI = (principal, annualInterestRate, tenureMonths) => {
        const monthlyInterestRate = annualInterestRate / 12 / 100;
        const emi =
            (principal * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, tenureMonths)) /
            (Math.pow(1 + monthlyInterestRate, tenureMonths) - 1);

        const totalAmountPayable = emi * tenureMonths;
        const interestPayable = totalAmountPayable - principal;

        return {
            emi: emi.toFixed(2),
            totalAmountPayable: totalAmountPayable.toFixed(2),
            interestPayable: interestPayable.toFixed(2),
        };
    };

    const { emi, totalAmountPayable, interestPayable } = calculateEMI(value, intrestrate, tenure);

    const [faqs, setFaqs] = useState([
        {
            question: "Age",
            answer:
                "20 Year and Above",
            open: true
        },
        {
            question: "Nature Of Employment",
            answer: "Self employed",
            open: false
        },
        {
            question:
                "Work Experience",
            answer: "3 Year",
            open: false
        },
        {
            question:
                "Minimum Educational Qualification",
            answer: "12th",
            open: false
        },
        {
            question:
                "Minimum Monthly Income",
            answer: "₹25,000 - ₹30,000 minimum net monthly income",
            open: false
        }
    ]);

    // Second FAQ 

    const [faqs2, setFaqs2] = useState([
        {
            question: "What is a Business loan?",
            answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
            open: true
        },
        {
            question: "What can I apply a Business loan for?",
            answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
            open: false
        },
        {
            question:"How do I check my eligibility for a KarmVed Business Loan?",
            answer: "You can check your eligibility by entering basic details such as income, business turnover, and credit score during the application process.",
            open: false
        },
        {
            question:"How long does it take to get a business loan approved?",
            answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
            open: false
        },
        {
            question:"Are there any hidden charges?",
            answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
            open: false
        },
        {
            question:"Can I prepay my business loan?",
            answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
            open: false
        }

    ]);

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };

    // Second FAQ 


    const toggleFAQ2 = index2 => {
        setFaqs2(
            faqs2.map((faq2, i1) => {
                if (i1 === index2) {
                    faq2.open = !faq2.open;
                } else {
                    faq2.open = false;
                }

                return faq2;
            })
        );
    };

    return (
        <div className='main-contanerr'>
            <div>
                <div>
                    <div>
                        <div>
                            <div>
                                <div className='w-[90%] mx-auto rounded-[30px] personal-loan-info-main'>
                                    <div className='px-[60px] py-[80px] xs:px-[20px] sm:px-[20px] rounded-[30px] mt-[22px] personal-loan-info'>
                                        <div className='flex gap-[20px] xs:block sm:block items-center'>
                                            <div className='flex gap-[8px] items-center'>
                                                <p><HiBadgeCheck className='text-[#F2BD70] text-[20px]' /></p>
                                                <p className='text-[#F2BD70] text-[15px] font-[500]'>100% Digital Process</p>
                                            </div>
                                            <div className='flex gap-[8px] items-center'>
                                                <p><HiBadgeCheck className='text-[#F2BD70] text-[20px]' /></p>
                                                <p className='text-[#F2BD70] text-[15px] font-[500]'>Low Interest Rates</p>
                                            </div>
                                            <div className='flex gap-[8px] items-center'>
                                                <p><HiBadgeCheck className='text-[#F2BD70] text-[20px]' /></p>
                                                <p className='text-[#F2BD70] text-[15px] font-[500]'>Quick Approvals</p>
                                            </div>
                                        </div>
                                        <p className='text-[48px] font-[900] w-[70%] xs:w-[100%] sm:w-[100%] mt-[10px] xs:text-[40px] sm:text-[40px] text-[#fff]'>No Limits, Just Growth!</p>
                                        <p className='text-[18px] font-[500] w-[70%] xs:w-[100%] sm:w-[100%] mt-[15px] text-[#fff]'>Fuel your business ambitions with a Business Loan of up to ₹50 Lakhs. Whether it's for expansion, working capital, or inventory purchase - we've got your back.</p>
                                        <div>
                                            <div className='flex xs:block sm:block gap-[20px] mt-[40px] items-center'>
                                                <a href='/log-in'><button className='flex gap-[5px] items-center text-[#563200] text-[16px] font-[500] bg-[#F2BD70] px-[20px] py-[10px] rounded-[40px] apply-now-btn'>Apply Now For Business Loan <FaAngleRight /></button></a>
                                                <a href='#personal-loan-calculator'><p className='flex gap-[5px] xs:px-[20px]  sm:px-[20px] xs:mt-[10px] sm:mt-[10px]  items-center text-[#DC9731] text-[16px] font-[500]'>Business Loan Calculator < FaAngleDown /></p></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-[90%] mx-auto mt-[50px]'>
                                    <div className='w-[45%] xs:w-[90%] sm:w-[90%]'>
                                        <p className='text-[48px] font-[900] leading-[50px] text-[#323232]'>Unlock Your Business Loan in 5 Easy Steps</p>
                                        <p className='text-[18px] font-[500] mt-[10px] text-[#323232]'>Getting a Business loan has never been this easy! With our seamless website application process, you can secure a Business loan in just a few minutes.</p>
                                    </div>
                                    <div className='grid grid-cols-4 gap-[15px] mt-[30px] xs:grid-cols-1 sm:grid-cols-2'>
                                        <div className='flex items-center gap-[15px]'>
                                            <img src={Firstnumberimg} alt='First Step'></img>
                                            <p className='text-[14px] font-[500] text-[#323232]'>Fill Out Business & Personal Details</p>
                                            <div className='w-[28px] h-[4px] bg-[#D6D6D6] rounded-[4px] xs:hidden'></div>
                                        </div>
                                        <div className='flex items-center gap-[15px]'>
                                            <img src={Secondnumberimg} alt='Second Step'></img>
                                            <p className='text-[14px] font-[500] text-[#323232]'>Complete application process and get instant online approval on Business loan up to ₹50 Lakh</p>
                                            <div className='w-[28px] h-[4px] bg-[#D6D6D6] rounded-[4px] xs:hidden'></div>
                                        </div>
                                        <div className='flex items-center gap-[15px]'>
                                            <img src={Thirdnumberimg} alt='Third Step'></img>
                                            <p className='text-[14px] font-[500] text-[#323232]'>Select Your Loan Amount and Tenure</p>
                                            <div className='w-[28px] h-[4px] bg-[#D6D6D6] rounded-[4px] xs:hidden'></div>
                                        </div>
                                        <div className='flex items-center gap-[15px]'>
                                            <img src={Fournumberimg} alt='Fourth Step'></img>
                                            <p className='text-[14px] font-[500] text-[#323232]'>Get the loan amount disbursed within hours</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='w-[90%] mx-auto'>
                                    <div className='px-[30px] xs:px-[20px] sm:px-[20px] xs:py-[20px] sm:py-[20px]  py-[50px] document-require-section-main rounded-[30px] mt-[50px]'>
                                        <div>
                                            <p className='text-[48px] font-[900] leading-[50px] text-[#323232] w-[50%] xs:w-[100%] sm:w-[100%] xs:text-[40px] sm:text-[40px]'>Documents required for loan application</p>
                                        </div>
                                        <div className='grid grid-cols-3 xs:grid-cols-1 sm:grid-cols-1 mx-auto gap-[40px] mt-[30px]'>
                                            <div className='flex gap-[10px] items-start'>
                                                <img src={DocumentIcon} alt='Documnet Icon'></img>
                                                <div>
                                                    <p className='text-[20px] font-[500] text-[#BD7000]'>Business Proof</p>
                                                    <div className='mt-[5px]'>
                                                        <p className='text-[14px] font-[500] text-[#323232]'>- GST Certificate</p>
                                                        <p className='text-[14px] font-[500] text-[#323232]'>- Business Registration Certificate</p>
                                                        <p className='text-[14px] font-[500] text-[#323232]'>- Trade License</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className='text-[20px] font-[500] text-[#BD7000]'>Identity Proof</p>
                                                    <p className='text-[12px] font-[500] text-[#BD7000]'>(one of the below)</p>
                                                    <div className='mt-[5px]'>
                                                        <p className='text-[14px] font-[500] text-[#323232]'>- Aadhaar Card</p>
                                                        <p className='text-[14px] font-[500] text-[#323232]'>- PAN Card</p>
                                                        <p className='text-[14px] font-[500] text-[#323232]'>- Passport</p>
                                                        <p className='text-[14px] font-[500] text-[#323232]'>- Voter's ID Card</p>
                                                        <p className='text-[14px] font-[500] text-[#323232]'>- Driving License</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex gap-[10px] items-start'>
                                                <img src={BankIcon} alt='Bank Icon'></img>
                                                <div>
                                                    <p className='text-[20px] font-[500] text-[#BD7000]'>Income Verification</p>
                                                    <div className='mt-[5px]'>
                                                        <p className='text-[14px] font-[500] text-[#323232]'>- Three months' bank statements detailing your income for verification purposes.</p>
                                                        <p className='text-[14px] mt-[10px] font-[500] text-[#323232]'>- Salaried individuals should furnish three months’ salary slips for additional verification.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex gap-[10px] items-start'>
                                                <img src={Usericon} alt='User Icon'></img>
                                                <div>
                                                    <p className='text-[20px] font-[500] text-[#BD7000]'>Photographs - passport size</p>
                                                    <div className='mt-[5px]'>
                                                        <p className='text-[14px] font-[500] text-[#323232]'>-  Attach 2-3 recent passport - sized photographs to complete the documentation process seamlessly.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className='w-[90%] mx-auto'>
                                    <div>
                                        <div>
                                            <div className='grid grid-cols-2 xs:grid-cols-1 sm:grid-cols-1 gap-[20px] mt-[50px]'>
                                                <div>
                                                    <p className='text-[48px] font-[500] text-[#323232] leading-[50px]'>Interest Rates and Transparent Charges</p>
                                                    <p className='text-[18px] font-[500] text-[#323232] mt-[10px]'>We prioritize transparency and fairness in all our financial offer. Here's what you need to know about our interest rates and charges.</p>
                                                    <a href='/log-in'><button className='flex gap-[5px] items-center text-[#563200] mt-[20px] text-[16px] font-[500] bg-[#F2BD70] px-[20px] py-[10px] rounded-[40px]'>Apply Now <FaAngleRight /></button></a>
                                                </div>
                                                <div>
                                                    <div className='grid grid-cols-2 xs:grid-cols-1 sm:grid-cols-1 gap-[20px]'>
                                                        <div>
                                                            <p className='text-[24px] font-[500] text-[#BD7000] '>Interest Rate</p>
                                                            <p className='text-[14px] font-[500] text-[#323232] mt-[20px]'>Starting from</p>
                                                            <p className='text-[48px] font-[700] leading-[50px] text-[#323232] mt-[5px]'>12% p.a.</p>
                                                            <p className='text-[14px] font-[500] text-[#323232] mt-[8px]'>Of the outstanding principal loan amount, calculated from the disbursement date, a fee of ₹500 plus applicable taxes will be charged for each prepayment.</p>
                                                        </div>
                                                        <div>
                                                            <p className='text-[24px] font-[500] text-[#BD7000] '>Loan Processing Charges</p>
                                                            <div className='flex gap-[20px]'>
                                                                <div>
                                                                    <p className='text-[48px] font-[700] leading-[50px] text-[#323232] mt-[5px]'>5%</p>
                                                                    <p className='text-[14px] font-[500] text-[#323232] mt-[8px]'>Loan amount + Applicable taxes</p>
                                                                </div>
                                                                <div>
                                                                    <p className='text-[48px] font-[700] leading-[50px] text-[#323232] mt-[5px]'>2%</p>
                                                                    <p className='text-[14px] font-[500] text-[#323232] mt-[8px]'>+GST on outstanding principal </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='grid grid-cols-2 xs:grid-cols-1 sm:grid-cols-1 gap-[20px] mt-[20px]'>
                                                            <div>
                                                                <p className='text-[24px] font-[500] text-[#BD7000] '>Prepayment Charges</p>
                                                                <p className='text-[14px] font-[500] text-[#323232] mt-[20px]'>Starting from</p>
                                                                <p className='text-[48px] font-[700] leading-[50px] text-[#323232] mt-[5px]'>20%</p>
                                                                <p className='text-[14px] font-[500] text-[#323232] mt-[8px]'>Of the outstanding principal loan amount, calculated from the disbursement date, a fee of ₹500 plus applicable taxes will be charged for each prepayment.</p>
                                                            </div>
                                                            <div>
                                                                <p className='text-[24px] font-[500] text-[#BD7000] '>Repayment Mode Swap Charges</p>
                                                                <p className='text-[14px] font-[500] text-[#323232] mt-[20px]'>Up to</p>
                                                                <p className='text-[48px] font-[700] leading-[50px] text-[#323232] mt-[5px]'>₹500</p>
                                                                <p className='text-[14px] font-[500] text-[#323232] mt-[8px]'>+ Taxes per instance</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className='w-[90%] mt-[50px] mx-auto bg-[#F8F8F8] px-[40px] rounded-[30px] border border-[#94949430] py-[30px] mt-[]' id='personal-loan-calculator'>
                                        <div>
                                            <div>
                                                <p className='text-[48px] font-[900] text-[#323232] text-center'>Business Loan EMI Calculator</p>
                                                <p className='text-[18px] font-[500] text-[#323232] text-center'>Use our Business Loan Calculator to get insights on your loan plan!</p>
                                            </div>
                                        </div>
                                        <div className='w-[100%] rounded-[30px] mt-[30px] bg-[#fff] mx-auto flex xs:block sm:block gap-[30px] calculator-main'>
                                            <div className='w-[70%] xs:w-[100%] sm:w-[100%] px-[40px] py-[40px]'>
                                                <div className='w-[100%]'>
                                                    <div className='flex xs:block sm:block  itmes-center justify-between'>
                                                        <div className='xs:mb-[10px] sm:mb-[10px]'>Enter Loan Amount</div>
                                                        <div><span className='px-[20px] py-[5px] mt-[20px] rounded-[40px]  text-[16px] font-[500] border border-[#CCCCCC]'>₹ {new Intl.NumberFormat('en-IN').format(value)}</span></div>
                                                    </div>
                                                    <input
                                                        type="range"
                                                        min={100000}
                                                        max={100000000}
                                                        value={value}
                                                        step={10000} // Adjust step size as needed
                                                        onChange={handleChange}
                                                        style={{ width: '100%' }}
                                                        className="custom-slider mt-[10px]"
                                                    />
                                                    <div className='flex itmes-center justify-between'>
                                                        <div><p className='text-[14px] font-[500]'>₹1 Lakhs</p></div>
                                                        <div><p className='text-[14px] font-[500]'>Max 10 Cr</p></div>
                                                    </div>
                                                </div>
                                                <div className='w-[100%] mt-[40px]'>
                                                    <div className='flex xs:block sm:block itmes-center justify-between'>
                                                        <div className='xs:mb-[10px] sm:mb-[10px]'>Enter Your Tenure (Months)</div>
                                                        <div><span className='px-[20px] py-[5px] mt-[20px] rounded-[40px] text-[16px] font-[500] border border-[#CCCCCC]'>{new Intl.NumberFormat('en-IN').format(tenure)}</span></div>
                                                    </div>
                                                    <input
                                                        type="range"
                                                        min={12}
                                                        max={180}
                                                        value={tenure}
                                                        step={2} // Adjust step size as needed
                                                        onChange={handleTenureChange}
                                                        style={{ width: '100%' }}
                                                        className=" mt-[10px]"
                                                    />
                                                    <div className='flex itmes-center justify-between'>
                                                        <div><p className='text-[14px] font-[500]'>12</p></div>
                                                        <div><p className='text-[14px] font-[500]'>Max 180</p></div>
                                                    </div>
                                                </div>
                                                <div className='w-[100%] mt-[40px]'>
                                                    <div className='flex xs:block sm:block itmes-center justify-between'>
                                                        <div className='xs:mb-[10px] sm:mb-[10px]'>Interest Rate (% P.A)</div>
                                                        <div><span className='px-[20px] py-[5px] mt-[20px] rounded-[40px] text-[16px] font-[500] border border-[#CCCCCC]'>{new Intl.NumberFormat('en-IN').format(intrestrate)}</span></div>
                                                    </div>
                                                    <input
                                                        type="range"
                                                        min={9}
                                                        max={30}
                                                        value={intrestrate}
                                                        step={1} // Adjust step size as needed
                                                        onChange={handleIntrest}
                                                        style={{ width: '100%' }}
                                                        className=" mt-[10px]"
                                                    />
                                                    <div className='flex itmes-center justify-between'>
                                                        <div><p className='text-[14px] font-[500]'>9</p></div>
                                                        <div><p className='text-[14px] font-[500]'>Max 30%</p></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-[35%] xs:w-[100%] sm:w-[100%] bg-[#FAFAFA] px-[40px]  xs:px-[20px] sm:px-[20px] py-[40px] rounded-[30px]'>
                                                <div>
                                                    <p className='text-[#636363] text-[18px] font-[500]'>Calculate EMI</p>
                                                    <p className='text-[36px] font-[900]'>{new Intl.NumberFormat('en-IN').format(emi)}/-</p>
                                                </div>
                                                <div>
                                                    <p className='text-[#636363] text-[18px] font-[500]'>Principal Payable</p>
                                                    <p className='text-[36px] font-[900]'>{new Intl.NumberFormat('en-IN').format(value)}/-</p>
                                                </div>
                                                <div>
                                                    <p className='text-[#636363] text-[18px] font-[500]'>Interest payable</p>
                                                    <p className='text-[36px] font-[900]'>{new Intl.NumberFormat('en-IN').format(interestPayable)}/-</p>
                                                </div>
                                                <hr className='mt-[20px]' />
                                                <div>
                                                    <p className='text-[36px] font-[900] text-[#BD7000]'>{new Intl.NumberFormat('en-IN').format(totalAmountPayable)}/-</p>
                                                    <p className='text-[#636363] text-[18px] font-[500]'>Total Amount Payable</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className='w-[90%] mx-auto rounded-[30px] mt-[100px] p-[50px] xs:p-[10px] sm:p-[10px] border border-[#DC973130] testimonial-main'>
                                        <div>
                                            <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] leading-[50px]'>Read What They Say!</p>
                                            <p className='mt-[10px]'>Our Customers Always Make Us Feel Special.</p>
                                        </div>
                                        <div>
                                            <div className='mt-[30px]'>
                                                <Swiper
                                                    slidesPerView={1}
                                                    spaceBetween={30}
                                                    loop={true}
                                                    modules={[Pagination, Autoplay]}
                                                    className="mySwiper"
                                                    autoplay={{
                                                        delay: 2000,
                                                        disableOnInteraction: false,
                                                    }}
                                                    breakpoints={{
                                                        // when window width is >= 640px
                                                        640: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 30,
                                                        },
                                                        // when window width is >= 768px
                                                        768: {
                                                            slidesPerView: 2,
                                                            spaceBetween: 40,
                                                        },
                                                        // when window width is >= 1024px
                                                        1024: {
                                                            slidesPerView: 2,
                                                            spaceBetween: 50,
                                                        },
                                                    }}
                                                >
                                                    <SwiperSlide>
                                                        <div>
                                                            <div className='customer-section-main rounded-[20px] px-[40px] xs:px-[10px] sm:px-[10px] py-[20px] border border-[#EFF0F6]'>
                                                                <div className='flex xs:block xs:gap-[10px] sm:block xs:gap-[10px] gap-[20px] items-center mt-[20px]'>
                                                                    <img src={Customer1} alt='Client Image'></img>
                                                                    <div>
                                                                        <p className='text-[16px] font-[500] text-[#636363]'>Business Loan Customer</p>
                                                                        <p className='text-[24px] font-[500] text-[#323232]'>Shah Mittal Maheshbhai</p>
                                                                        <div className='flex gap-[10px] mt-[15px]'>
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className='mt-[20px] bg-[#000]' />
                                                                <div className='mt-[20px]'>
                                                                    <p className='text-[18px] font-[500] text-[#636363]'>Business Loan Customer</p>
                                                                    <p className='text-[48px] font-[700] text-[#DC9731] leading-[50px]'>₹ 5,00,000</p>
                                                                </div>
                                                                <hr className='mt-[20px] bg-[#000]' />
                                                                <div className='flex justify-between items-center mt-[20px]'>
                                                                    <div>
                                                                        <img src={MuthootFinance} alt='Muthoot Finance Logo'></img>
                                                                        <p className='text-[12px] font-[500] text-[#636363] mt-[10px]'>*NBFCs approved partner</p>
                                                                    </div>
                                                                    <div>
                                                                        <img src={KarmvedBharatVector} alt='Vector Image'></img>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div>
                                                            <div className='customer-section-main rounded-[20px] px-[40px] xs:px-[10px] sm:px-[10px] py-[20px] border border-[#EFF0F6]'>
                                                                <div className='flex xs:block xs:gap-[10px] sm:block xs:gap-[10px] gap-[20px] items-center mt-[20px]'>
                                                                    <img src={Customer2} alt='Client Image'></img>
                                                                    <div>
                                                                        <p className='text-[16px] font-[500] text-[#636363]'>Business Loan Customer</p>
                                                                        <p className='text-[24px] font-[500] text-[#323232]'>Patel Jayesh Pravinbhai</p>
                                                                        <div className='flex gap-[10px] mt-[15px]'>
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className='mt-[20px] bg-[#000]' />
                                                                <div className='mt-[20px]'>
                                                                    <p className='text-[18px] font-[500] text-[#636363]'>Business Loan Customer</p>
                                                                    <p className='text-[48px] font-[700] text-[#DC9731] leading-[50px]'>₹ 5,00,000</p>
                                                                </div>
                                                                <hr className='mt-[20px] bg-[#000]' />
                                                                <div className='flex justify-between items-center mt-[20px]'>
                                                                    <div>
                                                                        <img src={HeroFinacorp} alt='Hero Fincorp Logo'></img>
                                                                        <p className='text-[12px] font-[500] text-[#636363] mt-[10px]'>*NBFCs approved partner</p>
                                                                    </div>
                                                                    <div>
                                                                        <img src={KarmvedBharatVector} alt='Vector Image'></img>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div>
                                                            <div className='customer-section-main rounded-[20px] px-[40px] xs:px-[10px] sm:px-[10px] py-[20px] border border-[#EFF0F6]'>
                                                                <div className='flex xs:block xs:gap-[10px] sm:block xs:gap-[10px] gap-[20px] items-center mt-[20px]'>
                                                                    <img src={Customer1} alt='Client Image'></img>
                                                                    <div>
                                                                        <p className='text-[16px] font-[500] text-[#636363]'>Business Loan Customer</p>
                                                                        <p className='text-[24px] font-[500] text-[#323232]'>Shah Mittal Maheshbhai</p>
                                                                        <div className='flex gap-[10px] mt-[15px]'>
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className='mt-[20px] bg-[#000]' />
                                                                <div className='mt-[20px]'>
                                                                    <p className='text-[18px] font-[500] text-[#636363]'>Business Loan Customer</p>
                                                                    <p className='text-[48px] font-[700] text-[#DC9731] leading-[50px]'>₹ 5,00,000</p>
                                                                </div>
                                                                <hr className='mt-[20px] bg-[#000]' />
                                                                <div className='flex justify-between items-center mt-[20px]'>
                                                                    <div>
                                                                        <img src={MuthootFinance} alt='Muthoot Finance Logo'></img>
                                                                        <p className='text-[12px] font-[500] text-[#636363] mt-[10px]'>*NBFCs approved partner</p>
                                                                    </div>
                                                                    <div>
                                                                        <img src={KarmvedBharatVector} alt='Vector Image'></img>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div>
                                                            <div className='customer-section-main rounded-[20px] px-[40px] xs:px-[10px] sm:px-[10px] py-[20px] border border-[#EFF0F6]'>
                                                                <div className='flex xs:block xs:gap-[10px] sm:block xs:gap-[10px] gap-[20px] items-center mt-[20px]'>
                                                                    <img src={Customer2} alt='Client Image'></img>
                                                                    <div>
                                                                        <p className='text-[16px] font-[500] text-[#636363]'>Business Loan Customer</p>
                                                                        <p className='text-[24px] font-[500] text-[#323232]'>Patel Jayesh Pravinbhai</p>
                                                                        <div className='flex gap-[10px] mt-[15px]'>
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className='mt-[20px] bg-[#000]' />
                                                                <div className='mt-[20px]'>
                                                                    <p className='text-[18px] font-[500] text-[#636363]'>Business Loan Customer</p>
                                                                    <p className='text-[48px] font-[700] text-[#DC9731] leading-[50px]'>₹ 5,00,000</p>
                                                                </div>
                                                                <hr className='mt-[20px] bg-[#000]' />
                                                                <div className='flex justify-between items-center mt-[20px]'>
                                                                    <div>
                                                                        <img src={HeroFinacorp} alt='Hero Fincorp Logo'></img>
                                                                        <p className='text-[12px] font-[500] text-[#636363] mt-[10px]'>*NBFCs approved partner</p>
                                                                    </div>
                                                                    <div>
                                                                        <img src={KarmvedBharatVector} alt='Vector Image'></img>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div>
                                                            <div className='customer-section-main rounded-[20px] px-[40px] xs:px-[10px] sm:px-[10px] py-[20px] border border-[#EFF0F6]'>
                                                                <div className='flex xs:block xs:gap-[10px] sm:block xs:gap-[10px] gap-[20px] items-center mt-[20px]'>
                                                                    <img src={Customer1} alt='Client Image'></img>
                                                                    <div>
                                                                        <p className='text-[16px] font-[500] text-[#636363]'>Business Loan Customer</p>
                                                                        <p className='text-[24px] font-[500] text-[#323232]'>Shah Mittal Maheshbhai</p>
                                                                        <div className='flex gap-[10px] mt-[15px]'>
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                            <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className='mt-[20px] bg-[#000]' />
                                                                <div className='mt-[20px]'>
                                                                    <p className='text-[18px] font-[500] text-[#636363]'>Business Loan Customer</p>
                                                                    <p className='text-[48px] font-[700] text-[#DC9731] leading-[50px]'>₹ 5,00,000</p>
                                                                </div>
                                                                <hr className='mt-[20px] bg-[#000]' />
                                                                <div className='flex justify-between items-center mt-[20px]'>
                                                                    <div>
                                                                        <img src={MuthootFinance} alt='Muthoot Finance Logo'></img>
                                                                        <p className='text-[12px] font-[500] text-[#636363] mt-[10px]'>*NBFCs approved partner</p>
                                                                    </div>
                                                                    <div>
                                                                        <img src={KarmvedBharatVector} alt='Vector Image'></img>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>

                                                </Swiper>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='w-[90%] mx-auto mt-[50px]'>
                                    <div>
                                        <p className='text-[48px] font-[900] text-[#323232] leading-[50px]'>Business Loan Eligibility Checklist:</p>
                                        <p className='text-[48px] font-[900] text-[#323232]'>Are You Ready to Apply?</p>
                                    </div>
                                    <div className="faqs xs:w-[90%] sm:w-[90%] mt-[60px]">
                                        {faqs.map((faq, index) => (
                                            <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                                        ))}
                                    </div>
                                </div>


                                <div className='w-[90%] mx-auto mt-[50px]'>
                                    <div>
                                        <p className='text-[48px] font-[900] text-[#323232] leading-[50px]'>Frequently asked questions</p>
                                        <p className='text-[18px] font-[500] text-[#323232] mt-[10px]'>Access answers to common queries in our FAQs</p>
                                    </div>
                                    <div className="faqs xs:w-[90%] sm:w-[90%] mt-[60px]">
                                        {faqs2.map((faq2, index2) => (
                                            <FAQ faq={faq2} index={index2} key={index2} toggleFAQ={toggleFAQ2} />
                                        ))}
                                    </div>
                                </div>

                                <div>
                                    <Contactussection />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Businessloan