import React, { useState } from 'react'
import { IoCall, IoPerson } from 'react-icons/io5'
import { IoMail } from "react-icons/io5";
import MoneyBag from './../../imgs/money-bag.svg'
import { HiBadgeCheck } from "react-icons/hi";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';




const Subscriptionplan = () => {

    const user_details = JSON.parse(localStorage.getItem('user_data'));
    const [paymentStatus, setPaymentStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const createOrder = async () => {
        setLoading(true);
        try {
            // Make a request to the backend to create the Razorpay order
            const response = await fetch('https://karmved-server.onrender.com/otp/payment/razorpay-payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    amount: 1,
                    currency: 'INR',
                }),
            });

            const data = await response.json();
            if (response.ok) {
                const order = data.order;
                handlePayment(order);
            } else {
                alert(data.message);
            }
        } catch (error) {
            console.error(error);
            alert('An error occurred while creating the order!');
        } finally {
            setLoading(false);
        }
    };

    const handlePayment = (order) => {
        if (!window.Razorpay) {
            alert('Razorpay SDK is not loaded!');
            return;
        }

        const options = {
            key: "rzp_test_8EiVb6e9nNGnVu", // Your Razorpay Key ID
            amount: order.amount, // Amount in paise
            currency: order.currency,
            name: 'Karmved Bharat Finance',
            description: 'Payment for your order : Financial Loan',
            order_id: order.id, // Order ID from backend
            handler: async function (response) {
                if (response.razorpay_payment_id) {
                    // Payment was successful, verify payment
                    await verifyPayment(response);
                } else {
                    setPaymentStatus('Payment was canceled.');
                }
            },
            modal: {
                ondismiss: () => {
                    setPaymentStatus('Payment was canceled.');
                },
            },
            theme: {
                color: "#F37254"
            },
        };

        const razorpay = new window.Razorpay(options);
        razorpay.open();

        // Fallback to check if payment is still pending after modal is closed
        razorpay.on('payment.failed', () => {
            setPaymentStatus('Payment failed.');
            toast.error('Payment failed.');
        });

        razorpay.on('payment.cancelled', () => {
            setPaymentStatus('Payment was canceled.');
            toast.error('Payment was canceled.');
        });
    };

    const verifyPayment = async (paymentResponse) => {
        try {
            const response = await fetch('https://karmved-server.onrender.com/otp/payment/razorpay-payment-verification', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    razorpay_payment_id: paymentResponse.razorpay_payment_id,
                    razorpay_order_id: paymentResponse.razorpay_order_id,
                    razorpay_signature: paymentResponse.razorpay_signature,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                if (data.paymentDetails.status === 'captured') {
                    setPaymentStatus('Payment verified successfully!');
                    toast.success('Payment verified successfully!');
                    navigate('/');
                } else if (data.paymentDetails.status === 'failed') {
                    setPaymentStatus('Payment failed!');
                    toast.error('Payment failed!');
                } else {
                    setPaymentStatus(`Payment status: ${data.paymentDetails.status}`);
                    toast.error(`Payment status: ${data.paymentDetails.status}`);
                }
            } else {
                setPaymentStatus(data.message || 'Payment verification failed!');
            }
        } catch (error) {
            setPaymentStatus('An error occurred during payment verification!');
            console.error('Error:', error);
        }
    };

    return (
        <div className='main-contanerr'>
            <div>
                <div>
                    <div>
                        <div>
                            <div className='flex justify-between xs:block sm:block xs:gap-[10px] sm:gap-[10px] items-center px-[50px] xs:px-[20px] sm:px-[20px] border border-[#ECECEC] eligible-loan-amount-main py-[30px]'>
                                <div>
                                    <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] text-[#323232] leading-[50px] xs:text-[40px] sm:text-[40px]'>Congrats, <span>{user_details?.fullName}</span>!</p>
                                    <p className='text-[18px] font-[500] text-[#323232] mt-[10px]'>You pre-approved offer is successfully confirmed. Process your loan offer now.</p>
                                </div>
                                <div className='xs:mt-[10px] sm:mt-[10px]'>
                                    <p className='text-[24px] font-[500] text-[#45AE4C]'>Eligible Loan Amount</p>
                                    <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[700] text-[#323232]'>₹ 4,54,880</p>
                                </div>
                            </div>
                            <div className='w-[95%] mx-auto mt-[50px]'>
                                <div>
                                    <div className='flex xs:block sm:block items-center'>
                                        <div className='w-[40%] xs:w-[95%] sm:w-[95%] xs:mx-auto sm:mx-auto px-[30px] py-[30px] rounded-[30px]'>
                                            <div className='flex gap-[10px] items-center mt-[20px]'>
                                                <p><IoPerson className='text-[35px] text-[#DC9731] ' /></p>
                                                <div>
                                                    <p className='text-[14px] font-[500] text-[#323232]'>Full Name:</p>
                                                    <span className='text-[20px] font-[500] text-[#BD7000]'>{user_details?.fullName}</span>
                                                </div>
                                            </div>
                                            <div className='flex gap-[10px] items-center mt-[10px]'>
                                                <p><IoCall className='text-[35px] text-[#DC9731] ' /></p>
                                                <div>
                                                    <p className='text-[14px] font-[500] text-[#323232]'>Mobile Number:</p>
                                                    <span className='text-[20px] font-[500] text-[#BD7000]'>{user_details?.mobileNo}</span>
                                                    
                                                </div>
                                            </div>
                                            <div className='flex gap-[10px] items-center mt-[10px]'>
                                                <p><IoMail className='text-[35px] text-[#DC9731] ' /></p>
                                                <div>
                                                    <p className='text-[14px] font-[500] text-[#323232]'>Email ID:</p>
                                                    <span className='text-[20px] font-[500] text-[#BD7000]'>{user_details?.email}</span>
                                                </div>
                                            </div>
                                            <div className='flex gap-[10px] items-center mt-[10px]'>
                                                <img src={MoneyBag} alt='Money Bag Icon' className='h-[35px] w-[35px]'></img>
                                                <div>
                                                    <p className='text-[14px] font-[500] text-[#323232]'>Loan Amount:</p>
                                                    {/* <span className='text-[20px] font-[500] text-[#BD7000]'>₹ {user_details?.loanAmount}</span> */}
                                                    <span className='text-[20px] font-[500] text-[#BD7000]'>₹ {new Intl.NumberFormat('en-IN').format(user_details?.loanAmount)}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='grid grid-cols-2 xs:grid-cols-1 sm:grid-cols-1 gap-[20px]'>
                                                <div className='subscribe-main px-[30px] py-[30px] rounded-[30px]'>
                                                    <span className='text-[#B40404] bg-[#FFE5E5] px-[10px] py-[10px] rounded-[12px] text-[13px] font-[500]'>Offer Valid Till 12 AM</span>
                                                    <p className='text-[16px] font-[500] text-[#323232] mt-[10px]'>Subscription Plan</p>
                                                    <div className='flex gap-[10px] items-center mt-[10px]'>
                                                        <p className='text-[36px] font-[900] text-[#323232]'>₹399/-</p>
                                                        <span className='text-[14px] font-[700] text-[#1DA867] px-[5px] py-[5px] rounded-[6px] bg-[#1DA8671C]'>75% off</span>
                                                        <p className='line-through text-[15px] font-[700] text-[#636363]'>₹1,499/-</p>
                                                    </div>
                                                    <hr className='bg-[#000] mt-[20px]' />
                                                    <div>
                                                        <div className='flex justify-between mt-[10px]'>
                                                            <p className='text-[16px] font-[700] text-[#636363]'>Subscription Amount:</p>
                                                            <p className='text-[16px] font-[700] text-[#636363]'>₹399.00</p>
                                                        </div>
                                                        <div className='flex justify-between mt-[10px]'>
                                                            <p className='text-[16px] font-[700] text-[#636363]'>GST (18%):</p>
                                                            <p className='text-[16px] font-[700] text-[#636363]'>₹71.82</p>
                                                        </div>
                                                        <hr className='bg-[#000] mt-[20px]' />
                                                        <div>
                                                            <div className='flex justify-between mt-[10px]'>
                                                                <p className='text-[16px] font-[700] text-[#046AB4]'>Total amount:</p>
                                                                <p className='text-[16px] font-[700] text-[#046AB4]'>₹470.82</p>
                                                            </div>
                                                        </div>
                                                        <button
                                                            onClick={createOrder}
                                                            className='px-[20px] py-[12px] bg-[#F37254] text-white font-bold text-[16px] rounded-[12px] mt-[20px] w-full'
                                                        >
                                                            {loading ? 'Processing Payment...' : 'Subscribe Now'}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='subscribe-main px-[30px] py-[30px] rounded-[30px]'>
                                                    <div>
                                                        <p className='text-[20px] font-[700] text-[#323232] '>Plan Benefits</p>
                                                        <hr className='bg-[#000] mt-[20px]' />
                                                    </div>
                                                    <div>
                                                        <div className='flex items-center gap-[8px] mt-[10px]'>
                                                            <HiBadgeCheck className='text-[#046AB4] text-[20px]'/>
                                                            <p className='text-[#046AB4] text-[14px] font-[500]'>Special Loan Expert Assigned</p>
                                                        </div>
                                                        <div className='flex items-center gap-[8px] mt-[10px]'>
                                                            <HiBadgeCheck className='text-[#046AB4] text-[20px]'/>
                                                            <p className='text-[#046AB4] text-[14px] font-[500]'>Loan Process in Multiple NBFCs</p>
                                                        </div>
                                                        <div className='flex items-center gap-[8px] mt-[10px]'>
                                                            <HiBadgeCheck className='text-[#046AB4] text-[20px]'/>
                                                            <p className='text-[#046AB4] text-[14px] font-[500]'>Get Your Personalized Tracking Portal</p>
                                                        </div>
                                                        <div className='flex items-center gap-[8px] mt-[10px]'>
                                                            <HiBadgeCheck className='text-[#046AB4] text-[20px]'/>
                                                            <p className='text-[#046AB4] text-[14px] font-[500]'>100% Paperless Process</p>
                                                        </div>
                                                        <div className='flex items-center gap-[8px] mt-[10px]'>
                                                            <HiBadgeCheck className='text-[#046AB4] text-[20px]'/>
                                                            <p className='text-[#046AB4] text-[14px] font-[500]'>Loan Processing Time 48 Hours</p>
                                                        </div>
                                                        <div className='flex items-center gap-[8px] mt-[10px]'>
                                                            <HiBadgeCheck className='text-[#046AB4] text-[20px]'/>
                                                            <p className='text-[#046AB4] text-[14px] font-[500]'>CIBIL Remains Unimpacted</p>
                                                        </div>
                                                        <div className='flex items-center gap-[8px] mt-[10px]'>
                                                            <HiBadgeCheck className='text-[#046AB4] text-[20px]'/>
                                                            <p className='text-[#046AB4] text-[14px] font-[500]'>On-Call Expert Support</p>
                                                        </div>
                                                        <div className='flex items-center gap-[8px] mt-[10px]'>
                                                            <HiBadgeCheck className='text-[#046AB4] text-[20px]'/>
                                                            <p className='text-[#046AB4] text-[14px] font-[500]'>Plan Validity only 3 Months</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Subscriptionplan