import React, { useState } from 'react'
import LowPriceIcon from './../../imgs/loweset-price.svg'
import IncereaseLoan from './../../imgs/increase-loan.svg'
import GetBalance from './../../imgs/get-balance.svg'
import SecurityIcon from './../../imgs/security-icon.svg'
import PhoneImage from './../../imgs/simple-step-secure-phone.svg'
import { FaChevronRight } from "react-icons/fa";
import CivilScroreImg from './../../imgs/check-civil-score.svg'
import Customer1 from './../../imgs/karmved-bharat-customer1.svg'
import Customer2 from './../../imgs/karmved-bharat-customer2.svg'


import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination, Autoplay } from 'swiper/modules';

import FAQ from './../../faq'

import TataCapital from './../../imgs/tata_capital_logo.svg'
import BajajFinance from './../../imgs/bajaj_finance_logo.svg'
import FibeLogo from './../../imgs/fibe_logo.svg'
import MuthootFinance from './../../imgs/muthoot_finance_logo.svg'
import HeroFinacorp from './../../imgs/hero_fincorp.svg'
import LTFinance from './../../imgs/lnt_finance.svg'
import StartrdChart from './../../imgs/Standard_Chartered.svg'
import HDFCLoan from './../../imgs/hdfc_home_loan.svg'
import Prefr from './../../imgs/prefr_logo.svg'

import KarmvedBharatVector from './../../imgs/karmved-bharat-vector.svg'

import { GiRoundStar } from "react-icons/gi";

import Contactussection from '../contact-us-section'



const Home = () => {

    const [value, setValue] = useState(100000);
    const [tenure, setTenure] = useState(12);
    const [intrestrate, setIntrestrate] = useState(9);


    const handleChange = (e) => {
        setValue(e.target.value);
    };
    const handleTenureChange = (e) => {
        setTenure(e.target.value);
    };
    const handleIntrest = (e) => {
        setIntrestrate(e.target.value)
    }

    const calculateEMI = (principal, annualInterestRate, tenureMonths) => {
        const monthlyInterestRate = annualInterestRate / 12 / 100;
        const emi =
            (principal * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, tenureMonths)) /
            (Math.pow(1 + monthlyInterestRate, tenureMonths) - 1);

        const totalAmountPayable = emi * tenureMonths;
        const interestPayable = totalAmountPayable - principal;

        return {
            emi: emi.toFixed(2),
            totalAmountPayable: totalAmountPayable.toFixed(2),
            interestPayable: interestPayable.toFixed(2),
        };
    };

    const { emi, totalAmountPayable, interestPayable } = calculateEMI(value, intrestrate, tenure);

    const [faqs, setFaqs] = useState([
        {
            // question: "How do I apply for a loan?",
            question: "How do I apply for ?",
            answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pharetra lorem eu dolor rhoncus, at scelerisque ligula gravida. Sed porta id mi sit amet convallis. Etiam iaculis massa sit amet lacus blandit sodales. Nulla ultrices velit a diam placerat congue. Pellentesque iaculis, ipsum quis eleifend dapibus, est dui eleifend ante, quis fermentum mi ligula quis nisl. Ut et ex dui. Integer id venenatis quam.",
            open: true
        },
        {
            question: "What is the 0% interest for up to 30 days offer?",
            answer: "You! The viewer!",
            open: false
        },
        {
            question:
                "Who is eligible to apply?",
            answer: "Any Indian citizen residing in India, above the age of 18, with a proof of current employment, earning a monthly salary of Rs.15,000 and above, a bank account, and a valid identity and address proof can apply.",
            open: false
        },
        {
            question:
                "Why is credit score important?",
            answer: "Any Indian citizen residing in India, above the age of 18, with a proof of current employment, earning a monthly salary of Rs.15,000 and above, a bank account, and a valid identity and address proof can apply.",
            open: false
        }
    ]);

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };


    return (
        <div className='main-contanerr'>
            <div>
                <div>
                    <div>
                        <div className='hero-section-main py-[150px] xs:py-[20px] xs:px-[30px] px-[200px]'>
                            <p className='text-[64px] xs:text-[40px] xs:leading-[50px] sm:text-[50px] font-[900] text-[#fff] leading-[70px] text-center'>India’s Sabse Best Financial Guide!</p>
                            <p className='text-[24px] xs:text-[13px] sm:text-[20px] font-[500] text-[#fff] leading-[33px] mt-[10px] text-center'>No complex processes—just honest support tailored for you.</p>
                            <a href='/log-in'><button className='px-[35px] py-[10px] bg-[#DC9731] text-[#ffffff] mx-auto block mt-[30px] rounded-[46px]'>Apply Now</button></a>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className='px-[200px] xs:px-[20px] sm:px-[20px] mt-[100px]'>
                                <p className='text-[24px] font-[500] text-[#323232] text-center'>We're Partnered  With The Best</p>
                                <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] text-center'> That’s why we’ve partnered <span className='text-[#DC9731] '>with industry-leading NBFCs</span></p>
                            </div>
                            <div>
                                <div>
                                    <div className='grid grid-cols-5 xs:grid-cols-2 sm:grid-cols-2 xs:gap-[20px] sm:gap-[20px] items-center w-[80%] mx-auto mt-[50px]'>
                                        <div>
                                            <img src={TataCapital} alt='Tata Capital Logo' className='mx-auto block'></img>
                                        </div>
                                        <div>
                                            <img src={BajajFinance} alt='Bajaj Finance Logo' className='mx-auto block' ></img>
                                        </div>
                                        <div>
                                            <img src={FibeLogo} alt='Fine Logo' className='mx-auto block' ></img>
                                        </div>
                                        <div>
                                            <img src={MuthootFinance} alt='Muthoot Finance Logo' className='mx-auto block' ></img>
                                        </div>
                                        <div>
                                            <img src={HeroFinacorp} alt='Hero Finacorp' className='mx-auto block' ></img>
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-4 xs:grid-cols-2 sm:grid-cols-2 xs:gap-[20px] sm:gap-[20px] w-[70%] mx-auto mt-[30px]'>
                                        <div>
                                            <img src={LTFinance} alt='L&T Finance' className='mx-auto block' ></img>
                                        </div>
                                        <div>
                                            <img src={StartrdChart} alt='Startd Chart' className='mx-auto block' ></img>
                                        </div>
                                        <div>
                                            <img src={HDFCLoan} alt='HDFC Home Loan' className='mx-auto block' ></img>
                                        </div>
                                        <div>
                                            <img src={Prefr} alt='Prefr Logo' className='mx-auto block' ></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='why-choose-us-main py-[70px] px-[120px] mt-[100px] xs:px-[20px] sm:px-[20px]  w-[90%] mx-auto border border-[#DC973130] rounded-[30px]'>
                        <h2 className='text-[24px] font-[500] text-[#323232] text-center'>Why Choose KarmVed Bharat?</h2>
                        {/* <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] text-center leading-[50px] mt-[20px]'>We're not just a loan service-we're your financial growth partner, <span className='text-[#DC9731]'>helping you navigate challenges and turn dreams into reality.</span></p> */}
                        <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] text-center leading-[50px] mt-[20px]'>We're not just a service-we're your financial growth partner, <span className='text-[#DC9731]'>helping you navigate challenges and turn dreams into reality.</span></p>
                        {/* <p className='text-[18px] font-[500] text-center text-[#323232] mt-[20px]'>We believe that access to financial support should be simple, fast, and stress-free. That's why we've redefined the way loans and financial consultations work in India. Whether it's an urgent personal loan or strategic financial advice, we're here to empower you every step of the way.</p> */}
                        <p className='text-[18px] font-[500] text-center text-[#323232] mt-[20px]'>We believe that access to financial support should be simple, fast, and stress-free. That's why we've redefined the way and financial consultations work in India. Whether it's an urgent personal  or strategic financial advice, we're here to empower you every step of the way.</p>
                        <div className='flex xs:block sm:block justify-around mt-[50px]'>
                            <div className=''>
                                <div className='flex gap-[10px] items-center'>
                                    <img src={LowPriceIcon} alt='Lowest Price Icon'></img>
                                    <div>
                                        <p className='text-[#BD7000] text-[20px] font-[500]'>Lowest Interest</p>
                                        <p className='text-[#323232] text-[14px] font-[500]'>Offer the lowest Interest rates.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[10px] items-center mt-[20px]'>
                                    <img src={GetBalance} alt='Get Balance Icon'></img>
                                    <div>
                                        <p className='text-[#BD7000] text-[20px] font-[500]'>Get up to ₹5,00,000</p>
                                        <p className='text-[#323232] text-[14px] font-[500]'>Get amount in your bank in 5-10 min.</p>
                                    </div>
                                </div>
                            </div>
                            <div className=''>
                                <div className='flex gap-[10px] items-center'>
                                    <img src={IncereaseLoan} alt='Graph Icon'></img>
                                    <div>
                                        {/* <p className='text-[#BD7000] text-[20px] font-[500]'>Increase your loan limit</p> */}
                                        <p className='text-[#BD7000] text-[20px] font-[500]'>Increase your limit</p>
                                        {/* <p className='text-[#323232] text-[14px] font-[500]'>Repay on time to boost credit for highest loan.</p> */}
                                        <p className='text-[#323232] text-[14px] font-[500]'>Repay on time to boost credit for highest.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[10px] items-center mt-[20px]'>
                                    <img src={SecurityIcon} alt='Security Icon'></img>
                                    <div>
                                        <p className='text-[#BD7000] text-[20px] font-[500]'>Safe & Secure</p>
                                        <p className='text-[#323232] text-[14px] font-[500]'>Your data is always protected.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-[90%] mx-auto rounded-[30px] subscripsion-plan-info-main'>
                        <div className='px-[60px] py-[80px] xs:px-[20px] sm:px-[20px] rounded-[30px] mt-[22px] subscripsion-plan-info'>
                            <p className='text-[48px] font-[900] leading-[50px] text-[#fff]'>{`Take control of your finance with a subscription plan’s to suit your profile.`}</p>
                            <p className='text-[18px] font-[500] mt-[45px] text-[#fff]'>We offer customized financial consultation plans for both salaried individuals and self-employed professionals</p>
                            <p className='text-[18px] font-[500] mt-[20px] text-[#fff]'>{`With our easy-to-start plans, you’ll gain access to the best expert advice in the industry, helping you make smarter financial decisions and achieve your goals. Kickstart your journey today, and enjoy a seamless process that gets you started in just minutes`}</p>
                        </div>
                    </div>
                    <div>
                        <div className='mt-[100px] w-[45%] xs:w-[90%] sm:w-[90%] mx-auto'>
                            <p className='text-[24px] font-[500] text-[#DC9731] text-center'>Interested? Here's the application process!</p>
                            {/* <p className='text-[48px] font-[900] text-center mt-[20px] leading-[50px]'>Simple steps to secure your loan Instantly</p> */}
                            <p className='text-[48px] font-[900] text-center mt-[20px] leading-[50px]'>Simple steps to secure your Instantly</p>
                            {/* <p className='text-[18px] font-[500] text-[#525252] mt-[25px] text-center'>{`Getting financial support shouldn’t be complicated. Follow these step-by-step instructions to navigate KarmVed Bharat’s seamless loan application process and turn your dreams into reality`}</p> */}
                            <p className='text-[18px] font-[500] text-[#525252] mt-[25px] text-center'>{`Getting financial support shouldn’t be complicated. Follow these step-by-step instructions to navigate KarmVed Bharat’s seamless application process and turn your dreams into reality`}</p>
                        </div>
                        <div>
                            <img src={PhoneImage} alt='Phone Image' className='mx-auto mt-[30px]'></img>
                        </div>
                    </div>
                    <div className='loan-calculator-main p-[80px] xs:p-[10px] sm:p-[10px] border border-[#DC973130] w-[90%] mx-auto rounded-[30px] mt-[20px]'>
                        <div className='w-[70%] xs:w-[98%] sm:w-[98%] mx-auto'>
                            <p className='text-[48px] xs:text-[35px] sm:text-[35px] font-[900] text-center leading-[50px]'>A list of calculators to help manage your finances.</p>
                            <p className='text-[18px] text-[#323232] font-[500] text-center mt-[25px]'>Choose your required finance calculators</p>
                            <form>
                                <select className='border border-[#CCCCCC] px-[20px] py-[10px] rounded-[30px] text-[#BD7000] loan-option mx-auto block mt-[20px]'>
                                    {/* <option>Personal Loan</option> */}
                                    <option>Personal </option>
                                    {/* <option>Business Loan</option> */}
                                    <option>Business </option>
                                </select>
                            </form>
                        </div>
                        <div>
                            <div>
                                <div className='w-[100%] rounded-[30px] mt-[30px] bg-[#fff] mx-auto flex xs:block sm:block gap-[30px] calculator-main'>
                                    <div className='w-[70%] xs:w-[100%] sm:w-[100%] px-[40px] py-[40px]'>
                                        <div className='w-[100%]'>
                                            <div className='flex xs:block sm:block  itmes-center justify-between'>
                                                {/* <div className='xs:mb-[10px] sm:mb-[10px]'>Enter Loan Amount</div> */}
                                                <div className='xs:mb-[10px] sm:mb-[10px]'>Enter Amount</div>
                                                <div><span className='px-[20px] py-[5px] mt-[20px] rounded-[40px]  text-[16px] font-[500] border border-[#CCCCCC]'>₹ {new Intl.NumberFormat('en-IN').format(value)}</span></div>
                                            </div>
                                            <input
                                                type="range"
                                                min={100000}
                                                max={100000000}
                                                value={value}
                                                step={10000} // Adjust step size as needed
                                                onChange={handleChange}
                                                style={{ width: '100%' }}
                                                className="custom-slider mt-[10px]"
                                            />
                                            <div className='flex itmes-center justify-between'>
                                                <div><p className='text-[14px] font-[500]'>₹1 Lakhs</p></div>
                                                <div><p className='text-[14px] font-[500]'>Max 10 Cr</p></div>
                                            </div>
                                        </div>
                                        <div className='w-[100%] mt-[40px]'>
                                            <div className='flex xs:block sm:block itmes-center justify-between'>
                                                <div className='xs:mb-[10px] sm:mb-[10px]'>Enter Your Tenure (Months)</div>
                                                <div><span className='px-[20px] py-[5px] mt-[20px] rounded-[40px] text-[16px] font-[500] border border-[#CCCCCC]'>{new Intl.NumberFormat('en-IN').format(tenure)}</span></div>
                                            </div>
                                            <input
                                                type="range"
                                                min={12}
                                                max={180}
                                                value={tenure}
                                                step={2} // Adjust step size as needed
                                                onChange={handleTenureChange}
                                                style={{ width: '100%' }}
                                                className=" mt-[10px]"
                                            />
                                            <div className='flex itmes-center justify-between'>
                                                <div><p className='text-[14px] font-[500]'>12</p></div>
                                                <div><p className='text-[14px] font-[500]'>Max 180</p></div>
                                            </div>
                                        </div>
                                        <div className='w-[100%] mt-[40px]'>
                                            <div className='flex xs:block sm:block itmes-center justify-between'>
                                                <div className='xs:mb-[10px] sm:mb-[10px]'>Interest Rate (% P.A)</div>
                                                <div><span className='px-[20px] py-[5px] mt-[20px] rounded-[40px] text-[16px] font-[500] border border-[#CCCCCC]'>{new Intl.NumberFormat('en-IN').format(intrestrate)}</span></div>
                                            </div>
                                            <input
                                                type="range"
                                                min={9}
                                                max={30}
                                                value={intrestrate}
                                                step={1} // Adjust step size as needed
                                                onChange={handleIntrest}
                                                style={{ width: '100%' }}
                                                className=" mt-[10px]"
                                            />
                                            <div className='flex itmes-center justify-between'>
                                                <div><p className='text-[14px] font-[500]'>9</p></div>
                                                <div><p className='text-[14px] font-[500]'>Max 30%</p></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-[35%] xs:w-[100%] sm:w-[100%] bg-[#FAFAFA] px-[40px]  xs:px-[20px] sm:px-[20px] py-[40px] rounded-[30px]'>
                                        <div>
                                            <p className='text-[#636363] text-[18px] font-[500]'>Calculate EMI</p>
                                            <p className='text-[36px] font-[900]'>{new Intl.NumberFormat('en-IN').format(emi)}/-</p>
                                        </div>
                                        <div>
                                            <p className='text-[#636363] text-[18px] font-[500]'>Principal Payable</p>
                                            <p className='text-[36px] font-[900]'>{new Intl.NumberFormat('en-IN').format(value)}/-</p>
                                        </div>
                                        <div>
                                            <p className='text-[#636363] text-[18px] font-[500]'>Interest payable</p>
                                            <p className='text-[36px] font-[900]'>{new Intl.NumberFormat('en-IN').format(interestPayable)}/-</p>
                                        </div>
                                        <hr className='mt-[20px]' />
                                        <div>
                                            <p className='text-[36px] font-[900] text-[#BD7000]'>{new Intl.NumberFormat('en-IN').format(totalAmountPayable)}/-</p>
                                            <p className='text-[#636363] text-[18px] font-[500]'>Total Amount Payable</p>
                                        </div>
                                    </div>
                                </div>
                                <p className='text-[#BD7000] text-[18px] font-[500] mt-[10px]'>*Subject to documentation and customer profile</p>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='w-[90%] mx-auto'>
                            <div className='flex xs:block sm:block gap-[20px] items-center mt-[100px]'>
                                <div>
                                    <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] leading-[50px]'>Check your creadit score in just <span className='text-[#DC9731]'>10 seconds</span></p>
                                    <p className='text-[18px] font-[500] text-[#525252] mt-[20px]'>Get expert advice on how to improve your credit score and keep it high</p>
                                    <button className='flex bg-[#DC9731] px-[20px] py-[15px] rounded-[30px] text-[16px] font-[500] text-[#fff] items-center gap-[10px] mt-[20px]'>Check credit score <FaChevronRight /></button>
                                </div>
                                <div>
                                    <img src={CivilScroreImg} alt='Civil Score Image' className='w-[100%] xs:mt-[10px] sm:mt-[10px]'></img>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='w-[90%] mx-auto rounded-[30px] mt-[100px] p-[50px] xs:p-[10px] sm:p-[10px] border border-[#DC973130] testimonial-main'>
                            <div>
                                <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] leading-[50px]'>Read What They Say!</p>
                                <p className='mt-[10px]'>Our Customers Always Make Us Feel Special.</p>
                            </div>
                            <div>
                                <div className='mt-[30px]'>
                                    <Swiper
                                        slidesPerView={1}
                                        spaceBetween={30}
                                        loop={true}
                                        modules={[Pagination,Autoplay]}
                                        className="mySwiper"
                                        autoplay={{
                                            delay: 2000,
                                            disableOnInteraction: false,
                                        }}
                                        breakpoints={{
                                            // when window width is >= 640px
                                            640: {
                                                slidesPerView: 1,
                                                spaceBetween: 30,
                                            },
                                            // when window width is >= 768px
                                            768: {
                                                slidesPerView: 2,
                                                spaceBetween: 40,
                                            },
                                            // when window width is >= 1024px
                                            1024: {
                                                slidesPerView: 2,
                                                spaceBetween: 50,
                                            },
                                        }}
                                    >
                                        <SwiperSlide>
                                            <div>
                                                <div className='customer-section-main rounded-[20px] px-[40px] xs:px-[10px] sm:px-[10px] py-[20px] border border-[#EFF0F6]'>
                                                    <div className='flex xs:block xs:gap-[10px] sm:block xs:gap-[10px] gap-[20px] items-center mt-[20px]'>
                                                        <img src={Customer1} alt='Client Image'></img>
                                                        <div>
                                                            {/* <p className='text-[16px] font-[500] text-[#636363]'>Personal Loan Customer</p> */}
                                                            <p className='text-[16px] font-[500] text-[#636363]'>Customer</p>
                                                            <p className='text-[24px] font-[500] text-[#323232]'>Shah Mittal Maheshbhai</p>
                                                            <div className='flex gap-[10px] mt-[15px]'>
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className='mt-[20px] bg-[#000]' />
                                                    <div className='mt-[20px]'>
                                                        {/* <p className='text-[18px] font-[500] text-[#636363]'>Personal Loan Customer</p> */}
                                                        <p className='text-[18px] font-[500] text-[#636363]'>Customer</p>
                                                        <p className='text-[48px] font-[700] text-[#DC9731] leading-[50px]'>₹ 5,00,000</p>
                                                    </div>
                                                    <hr className='mt-[20px] bg-[#000]' />
                                                    <div className='flex justify-between items-center mt-[20px]'>
                                                        <div>
                                                            <img src={MuthootFinance} alt='Muthoot Finance Logo'></img>
                                                            <p className='text-[12px] font-[500] text-[#636363] mt-[10px]'>*NBFCs approved partner</p>
                                                        </div>
                                                        <div>
                                                            <img src={KarmvedBharatVector} alt='Vector Image'></img>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div>
                                                <div className='customer-section-main rounded-[20px] px-[40px] xs:px-[10px] sm:px-[10px] py-[20px] border border-[#EFF0F6]'>
                                                    <div className='flex xs:block xs:gap-[10px] sm:block xs:gap-[10px] gap-[20px] items-center mt-[20px]'>
                                                        <img src={Customer2} alt='Client Image'></img>
                                                        <div>
                                                            {/* <p className='text-[16px] font-[500] text-[#636363]'>Personal Loan Customer</p> */}
                                                            <p className='text-[16px] font-[500] text-[#636363]'>Customer</p>
                                                            <p className='text-[24px] font-[500] text-[#323232]'>Patel Jayesh Pravinbhai</p>
                                                            <div className='flex gap-[10px] mt-[15px]'>
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className='mt-[20px] bg-[#000]' />
                                                    <div className='mt-[20px]'>
                                                        {/* <p className='text-[18px] font-[500] text-[#636363]'>Personal Loan Customer</p> */}
                                                        <p className='text-[18px] font-[500] text-[#636363]'>Customer</p>
                                                        <p className='text-[48px] font-[700] text-[#DC9731] leading-[50px]'>₹ 5,00,000</p>
                                                    </div>
                                                    <hr className='mt-[20px] bg-[#000]' />
                                                    <div className='flex justify-between items-center mt-[20px]'>
                                                        <div>
                                                            <img src={HeroFinacorp} alt='Hero Fincorp Logo'></img>
                                                            <p className='text-[12px] font-[500] text-[#636363] mt-[10px]'>*NBFCs approved partner</p>
                                                        </div>
                                                        <div>
                                                            <img src={KarmvedBharatVector} alt='Vector Image'></img>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div>
                                                <div className='customer-section-main rounded-[20px] px-[40px] xs:px-[10px] sm:px-[10px] py-[20px] border border-[#EFF0F6]'>
                                                    <div className='flex xs:block xs:gap-[10px] sm:block xs:gap-[10px] gap-[20px] items-center mt-[20px]'>
                                                        <img src={Customer1} alt='Client Image'></img>
                                                        <div>
                                                            {/* <p className='text-[16px] font-[500] text-[#636363]'>Personal Loan Customer</p> */}
                                                            <p className='text-[16px] font-[500] text-[#636363]'>Customer</p>
                                                            <p className='text-[24px] font-[500] text-[#323232]'>Shah Mittal Maheshbhai</p>
                                                            <div className='flex gap-[10px] mt-[15px]'>
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className='mt-[20px] bg-[#000]' />
                                                    <div className='mt-[20px]'>
                                                        {/* <p className='text-[18px] font-[500] text-[#636363]'>Personal Loan Customer</p> */}
                                                        <p className='text-[18px] font-[500] text-[#636363]'>Customer</p>
                                                        <p className='text-[48px] font-[700] text-[#DC9731] leading-[50px]'>₹ 5,00,000</p>
                                                    </div>
                                                    <hr className='mt-[20px] bg-[#000]' />
                                                    <div className='flex justify-between items-center mt-[20px]'>
                                                        <div>
                                                            <img src={MuthootFinance} alt='Muthoot Finance Logo'></img>
                                                            <p className='text-[12px] font-[500] text-[#636363] mt-[10px]'>*NBFCs approved partner</p>
                                                        </div>
                                                        <div>
                                                            <img src={KarmvedBharatVector} alt='Vector Image'></img>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div>
                                                <div className='customer-section-main rounded-[20px] px-[40px] xs:px-[10px] sm:px-[10px] py-[20px] border border-[#EFF0F6]'>
                                                    <div className='flex xs:block xs:gap-[10px] sm:block xs:gap-[10px] gap-[20px] items-center mt-[20px]'>
                                                        <img src={Customer2} alt='Client Image'></img>
                                                        <div>
                                                            {/* <p className='text-[16px] font-[500] text-[#636363]'>Personal Loan Customer</p> */}
                                                            <p className='text-[16px] font-[500] text-[#636363]'>Customer</p>
                                                            <p className='text-[24px] font-[500] text-[#323232]'>Patel Jayesh Pravinbhai</p>
                                                            <div className='flex gap-[10px] mt-[15px]'>
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className='mt-[20px] bg-[#000]' />
                                                    <div className='mt-[20px]'>
                                                        {/* <p className='text-[18px] font-[500] text-[#636363]'>Personal Loan Customer</p> */}
                                                        <p className='text-[18px] font-[500] text-[#636363]'>Customer</p>
                                                        <p className='text-[48px] font-[700] text-[#DC9731] leading-[50px]'>₹ 5,00,000</p>
                                                    </div>
                                                    <hr className='mt-[20px] bg-[#000]' />
                                                    <div className='flex justify-between items-center mt-[20px]'>
                                                        <div>
                                                            <img src={HeroFinacorp} alt='Hero Fincorp Logo'></img>
                                                            <p className='text-[12px] font-[500] text-[#636363] mt-[10px]'>*NBFCs approved partner</p>
                                                        </div>
                                                        <div>
                                                            <img src={KarmvedBharatVector} alt='Vector Image'></img>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div>
                                                <div className='customer-section-main rounded-[20px] px-[40px] xs:px-[10px] sm:px-[10px] py-[20px] border border-[#EFF0F6]'>
                                                    <div className='flex xs:block xs:gap-[10px] sm:block xs:gap-[10px] gap-[20px] items-center mt-[20px]'>
                                                        <img src={Customer1} alt='Client Image'></img>
                                                        <div>
                                                            {/* <p className='text-[16px] font-[500] text-[#636363]'>Personal Loan Customer</p> */}
                                                            <p className='text-[16px] font-[500] text-[#636363]'>Customer</p>
                                                            <p className='text-[24px] font-[500] text-[#323232]'>Shah Mittal Maheshbhai</p>
                                                            <div className='flex gap-[10px] mt-[15px]'>
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                                <GiRoundStar className='text-[#DC9731] text-[18px]' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className='mt-[20px] bg-[#000]' />
                                                    <div className='mt-[20px]'>
                                                        {/* <p className='text-[18px] font-[500] text-[#636363]'>Personal Loan Customer</p> */}
                                                        <p className='text-[18px] font-[500] text-[#636363]'>Customer</p>
                                                        <p className='text-[48px] font-[700] text-[#DC9731] leading-[50px]'>₹ 5,00,000</p>
                                                    </div>
                                                    <hr className='mt-[20px] bg-[#000]' />
                                                    <div className='flex justify-between items-center mt-[20px]'>
                                                        <div>
                                                            <img src={MuthootFinance} alt='Muthoot Finance Logo'></img>
                                                            <p className='text-[12px] font-[500] text-[#636363] mt-[10px]'>*NBFCs approved partner</p>
                                                        </div>
                                                        <div>
                                                            <img src={KarmvedBharatVector} alt='Vector Image'></img>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div>
                            <div className='w-[90%] mx-auto mt-[100px]'>
                                <div>
                                    <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] leading-[50px]'>Frequently asked questions</p>
                                    <p className='text-[18px] font-[500] mt-[25px]'>Access answers to common queries in our FAQs</p>
                                </div>
                                <div className="faqs xs:w-[90%] sm:w-[90%] mt-[60px]">
                                    {faqs.map((faq, index) => (
                                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <Contactussection />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home