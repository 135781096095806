import React from 'react'
import Contactussection from './contact-us-section'

const Privacypolicy = () => {
    return (
        <div className='main-contanerr'>
            <div>
                <div>
                    <div>
                        <div className='w-[90%] mx-auto mt-[50px]'>
                            <p className='text-[20px] font-[500] text-[#525252]'>At KarmVed Bharat, we value the trust and privacy of our users. This Privacy Policy outlines how we collect, use, share, and protect your personal information while interacting with our services. By accessing or using our services, you agree to the terms set forth in this Privacy Policy.</p>
                            <div>
                                <p className='text-[#525252] text-[32px] font-[500]'>Information We Collect</p>
                                <p className='text-[20px] font-[500] text-[#525252]'>We collect personal information to provide and improve our services. The types of information we collect include:</p>
                                <p className='text-[20px] font-[500] text-[#525252]'></p>
                                <p className='text-[20px] font-[500] text-[#525252] mt-[10px]'>1. Information You Provide Directly</p>
                                <ul className='list-disc list-inside mt-[5px] ml-[15px]'>
                                    <li className='text-[16px] font-[500] text-[#525252]'>Name, address, email address, phone number, marital status, employment details, assets, and income.</li>
                                    <li className='text-[16px] font-[500] text-[#525252]'>Information submitted through applications, forms, or inquiries.</li>
                                </ul>
                                <p className='text-[20px] font-[500] text-[#525252] mt-[10px]'>2. Information from Third Parties</p>
                                <ul className='list-disc list-inside mt-[5px] ml-[15px]'>
                                    <li className='text-[16px] font-[500] text-[#525252]'>Details from financial institutions, brokers, custodians, and other service providers.</li>
                                </ul>
                                <p className='text-[20px] font-[500] text-[#525252] mt-[10px]'>3. Automatic Information Collection</p>
                                <ul className='list-disc list-inside mt-[5px] ml-[15px]'>
                                    <li className='text-[16px] font-[500] text-[#525252]'>Technical data such as IP address, browser type, operating system, and device identifiers.</li>
                                    <li className='text-[16px] font-[500] text-[#525252]'>Cookies and mobile advertising IDs for analytics and advertising</li>
                                </ul>
                                <p className='text-[#525252] text-[32px] font-[500] mt-[10px]'>How We Use Your Information</p>
                                <p className='text-[20px] font-[500] text-[#525252] mt-[10px]'>We use the collected data for the following purposes:</p>
                                <ul className='list-disc list-inside mt-[5px] ml-[15px]'>
                                    <li className='text-[16px] font-[500] text-[#525252]'>Processing applications and transactions you authorize.</li>
                                    <li className='text-[16px] font-[500] text-[#525252]'>Managing your account and providing customer service.</li>
                                    <li className='text-[16px] font-[500] text-[#525252]'>Ensuring compliance with legal and regulatory obligations.</li>
                                    <li className='text-[16px] font-[500] text-[#525252]'>Personalizing your experience and offering tailored recommendations.</li>
                                    <li className='text-[16px] font-[500] text-[#525252]'>Communicating updates about our services or third-party offers (with your consent).</li>
                                </ul>
                                <p className='text-[#525252] text-[32px] font-[500] mt-[10px]'>Information Sharing</p>
                                <p className='text-[20px] font-[500] text-[#525252] mt-[10px]'>We do not sell or trade your personal information. However, we may share your information in the following scenarios:</p>
                                <ul className='list-disc list-inside mt-[5px] ml-[15px]'>
                                    <li className='text-[16px] font-[500] text-[#525252]'>Service Providers: With trusted third parties who assist in operating our services and have agreed to confidentiality obligations.</li>
                                    <li className='text-[16px] font-[500] text-[#525252]'>Legal Requirements: To comply with applicable laws, regulations, or legal processes.</li>
                                    <li className='text-[16px] font-[500] text-[#525252]'>Business Transactions: In connection with mergers, acquisitions, or restructuring, where your data may be transferred as part of the transaction.</li>
                                </ul>
                                <p className='text-[#525252] text-[32px] font-[500] mt-[10px]'>Security Measures</p>
                                <p className='text-[20px] font-[500] text-[#525252] mt-[10px]'>We adopt industry-standard measures to protect your personal information, including:</p>
                                <ul className='list-disc list-inside mt-[5px] ml-[15px]'>
                                    <li className='text-[16px] font-[500] text-[#525252]'>Secure data storage and restricted access.</li>
                                    <li className='text-[16px] font-[500] text-[#525252]'>Encryption and regular security assessments to prevent unauthorized access.</li>
                                </ul>
                                <p className='text-[16px] font-[500] text-[#525252] mt-[10px]'>However, no system is completely secure. We encourage you to notify us immediately at <a className='text-[#1100FF]' href='mailto:info@karmvedbharat.com'>info@karmvedbharat.com</a> if you suspect a data breach or vulnerability.</p>

                                <p className='text-[#525252] text-[32px] font-[500] mt-[10px]'>Data Accuracy</p>
                                <p className='text-[20px] font-[500] text-[#525252] mt-[10px]'>We aim to maintain accurate and up-to-date personal data. You are responsible for notifying us of any changes to your information.</p>

                                <p className='text-[#525252] text-[32px] font-[500] mt-[10px]'>Your Rights</p>
                                <p className='text-[20px] font-[500] text-[#525252] mt-[10px]'>You may have the following rights regarding your personal data:</p>
                                <ul className='list-disc list-inside mt-[5px] ml-[15px]'>
                                    <li className='text-[16px] font-[500] text-[#525252]'>Access to information we hold about you.</li>
                                    <li className='text-[16px] font-[500] text-[#525252]'>Request corrections or deletions of inaccurate or outdated data.</li>
                                    <li className='text-[16px] font-[500] text-[#525252]'>Opt-out of receiving promotional communications.</li>
                                </ul>

                                <p className='text-[#525252] text-[32px] font-[500] mt-[10px]'>Cookies and Advertising IDs</p>
                                <p className='text-[20px] font-[500] text-[#525252] mt-[10px]'>We use cookies to improve user experience, track website interactions, and provide personalized content.</p>
                                <ul className='list-disc list-inside mt-[5px] ml-[15px]'>
                                    <li className='text-[16px] font-[500] text-[#525252]'>You can manage cookie preferences through your browser settings.</li>
                                    <li className='text-[16px] font-[500] text-[#525252]'>Mobile Advertising IDs may be used for analytics and targeted advertising. You can reset or disable these IDs in your device settings.</li>
                                </ul>

                                <p className='text-[#525252] text-[32px] font-[500] mt-[10px]'>Compliance and Jurisdiction</p>
                                <p className='text-[20px] font-[500] text-[#525252] mt-[10px]'>This Privacy Policy complies with data protection laws applicable in India. Any disputes will fall under the exclusive jurisdiction of courts in Surat, Gujarat, unless otherwise stated.</p>

                                <p className='text-[#525252] text-[32px] font-[500] mt-[10px]'>Grievance Redressal</p>
                                <p className='text-[20px] font-[500] text-[#525252] mt-[10px]'>For complaints, inquiries, or feedback regarding this Privacy Policy, please contact:</p>
                                <ul className='list-disc list-inside mt-[5px] ml-[15px]'>
                                    <li className='text-[16px] font-[500] text-[#525252]'>Email : <a href='mailto:info@karmvedbharat.com' className='text-[#1100FF]'>info@karmvedbharat.com</a> <br /> Our team will address your concerns promptly and, where necessary, collaborate with relevant regulatory authorities to resolve disputes.</li>
                                </ul>

                                <p className='text-[#525252] text-[32px] font-[500] mt-[10px]'>Policy Updates</p>
                                <p className='text-[20px] font-[500] text-[#525252] mt-[10px]'>We may revise this Privacy Policy periodically. Significant changes will be communicated through email notifications or website updates. It is your responsibility to review the Privacy Policy regularly.</p>

                                <p className='mt-[20px] text-[32px] font-[500] text-[#525252]'>Thank you for trusting KarmVed Bharat. Your privacy is our priority.</p>
                            </div>
                        </div>
                        <div>
                            <Contactussection/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacypolicy