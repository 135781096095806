import React from 'react'
import DocumentIcon from './../../imgs/document_icon.svg'
import BankIcon from './../../imgs/bank_icon.svg'
import ManeyBagIcon from './../../imgs/money-bag.svg'
import DirectionIcon from './../../imgs/directions.svg'
import WhyUsIcon from './../../imgs/why-us-main-icon.svg'

import TataCapital from './../../imgs/tata_capital_logo.svg'
import BajajFinance from './../../imgs/bajaj_finance_logo.svg'
import FibeLogo from './../../imgs/fibe_logo.svg'
import MuthootFinance from './../../imgs/muthoot_finance_logo.svg'
import HeroFinacorp from './../../imgs/hero_fincorp.svg'
import LTFinance from './../../imgs/lnt_finance.svg'
import StartrdChart from './../../imgs/Standard_Chartered.svg'
import HDFCLoan from './../../imgs/hdfc_home_loan.svg'
import Prefr from './../../imgs/prefr_logo.svg'

import Contactussection from '../contact-us-section'

const Aboutus = () => {
    return (
        <div className='main-contanerr'>
            <div>
                <div>
                    <div>
                        <div className='w-[90%] mx-auto rounded-[30px] about-us-info-main'>
                            <div className='px-[60px] py-[80px] xs:px-[20px] sm:px-[20px] rounded-[30px] mt-[22px] subscripsion-plan-info'>
                                <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] w-[60%] xs:w-[100%] sm:w-[100%] leading-[50px] text-[#fff]'>{`One Dream at a Time – Simplifying Finances, Strengthening Futures!`}</p>
                                <p className='text-[18px] font-[500] w-[60%] xs:w-[100%] sm:w-[100%] mt-[45px] text-[#fff]'>Our mission and vision come together as a single goal: to simplify and transform financial services in India, empowering individuals and businesses to thrive. We aim to create a financially secure and empowered Bharat where every dream has the opportunity to become reality.</p>
                            </div>
                        </div>
                        <div>
                            <div className='w-[90%] mx-auto mt-[100px]'>
                                <div className='w-[70%] xs:w-[100%] sm:w-[100%]'>
                                    <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] leading-[50px]'>Making Dreams Happen, One Step at a Time</p>
                                    <p className='text-[18px] font-[500] mt-[20px]'>In a country as vibrant and diverse as India, financial needs are just as unique as its people. Whether it’s planning for a brighter future, starting a small business, or managing day-to-day expenses, KarmVed Bharat stands by you. We understand that money is not just currency—it’s the fuel that powers your dreams.</p>
                                    <p className='text-[18px] font-[500] mt-[20px]'>Through innovative technology and a customer-first approach, we’ve built a seamless platform where you can access personalized financial guidance without the hassle of traditional methods.</p>
                                    <p className='text-[18px] font-[500] mt-[20px]'>We believe finances should empower, not overwhelm. We’ve created a platform designed to simplify financial services and make them accessible to everyone, no matter where they are. From the comfort of your home, we bring you expert financial consultation and services that align with your unique aspirations</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='aapka-vishvas-main py-[70px] rounded-[30px] border border-[#DC973130] mt-[100px] w-[90%] mx-auto'>
                                <div className='mt-[20px]'>
                                    <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] text-center'>Aapka Vishwas,</p>
                                    <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] text-center'>Aapka Saathi.</p>
                                    {/* <p className='text-[18px] font-[500] mt-[20px] text-[#323232] w-[45%] xs:w-[90%] sm:w-[90%] text-center mx-auto'>We’ve simplified loans and financial services to make them accessible to every Indian. No long queues, no endless paperwork-just quick solutions to help you move forward.</p> */}
                                    <p className='text-[18px] font-[500] mt-[20px] text-[#323232] w-[45%] xs:w-[90%] sm:w-[90%] text-center mx-auto'>We’ve simplified and financial services to make them accessible to every Indian. No long queues, no endless paperwork-just quick solutions to help you move forward.</p>
                                </div>
                                <div className='flex xs:block sm:block w-[60%] xs:w-[90%] sm:w-[90%] mx-auto gap-[40px] mt-[40px] '>
                                    <div>
                                        <div className='flex items-center xs:items-start sm:items-start gap-[10px] '>
                                            <div>
                                                <img src={DocumentIcon} alt='Document Icon' className='w-[50px] h-[50px]'></img>
                                            </div>
                                            <div>
                                                <p className='text-[20px] font-[500] text-[#BD7000]'>Minimal Documentation</p>
                                                <p className='text-[14px] font-[500] text-[#323232]'>Say goodbye to lengthy forms-just the basics are enough to get started.</p>
                                            </div>
                                        </div>
                                        <div className='flex items-center xs:items-start sm:items-start  gap-[10px] mt-[33px]'>
                                            <div>
                                                <img src={BankIcon} alt='Bank Icon' className='w-[50px] h-[50px]'></img>
                                            </div>
                                            <div>
                                                <p className='text-[20px] font-[500] text-[#BD7000]'>Direct Bank Transfers</p>
                                                <p className='text-[14px] font-[500] text-[#323232]'>Fast and secure disbursement straight into your bank account.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex items-center xs:items-start sm:items-start gap-[10px] '>
                                            <div>
                                                <img src={ManeyBagIcon} alt='Money Bag Icon' className='w-[50px] h-[50px]'></img>
                                            </div>
                                            <div>
                                                {/* <p className='text-[20px] font-[500] text-[#BD7000]'>Instant Loan Approvals</p> */}
                                                <p className='text-[20px] font-[500] text-[#BD7000]'>Instant Approvals</p>
                                                {/* <p className='text-[14px] font-[500] text-[#323232]'>Get your loan approved in minutes without unnecessary delays.</p> */}
                                                <p className='text-[14px] font-[500] text-[#323232]'>Get your approved in minutes without unnecessary delays.</p>
                                            </div>
                                        </div>
                                        <div className='flex items-center xs:items-start sm:items-start gap-[10px] mt-[33px]'>
                                            <div>
                                                <img src={DirectionIcon} alt='Direction Icon' className='w-[50px] h-[50px]'></img>
                                            </div>
                                            <div>
                                                {/* <p className='text-[20px] font-[500] text-[#BD7000]'>Flexible Loan Options</p> */}
                                                <p className='text-[20px] font-[500] text-[#BD7000]'>Flexible Options</p>
                                                <p className='text-[14px] font-[500] text-[#323232]'>Tailored plans to suit your needs, whether it’s for education, business, or personal goals.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div>
                            <div>
                                <div className='mt-[80px] w-[60%] xs:w-[90%] sm:w-[90%] mx-auto'>
                                    <div>
                                        <img src={WhyUsIcon} alt='Why US Icon' className='mx-auto block'></img>
                                        <p className='text-[24px] font-[500] text-center text-[#DC9731] mt-[20px]'>Why Choose Us?</p>
                                        <p className='text-[48px] font-[900] xs:text-[40px] sm:text-[40px] text-center text-[#323232]'>Trusted nationwide for your financial needs.</p>
                                    </div>
                                    <div className='flex xs:block sm:block gap-[40px] mt-[40px] '>
                                        <div>
                                            <div className='flex gap-[10px] '>
                                                <div>
                                                    <img src={DocumentIcon} alt='Document Icon' className='w-[70px] h-[70px]'></img>
                                                </div>
                                                <div>
                                                    <p className='text-[20px] font-[500] text-[#BD7000]'>50,000+ Satisfied Customers</p>
                                                    <p className='text-[14px] font-[500] text-[#323232]'>Our ever-growing customer base is a testament to the trust and authenticity we’ve built over time.</p>
                                                </div>
                                            </div>
                                            <div className='flex gap-[10px] mt-[33px]'>
                                                <div>
                                                    <img src={BankIcon} alt='Bank Icon' className='w-[70px] h-[70px]'></img>
                                                </div>
                                                <div>
                                                    <p className='text-[20px] font-[500] text-[#BD7000]'>Partnerships with Top-Tier NBFCs</p>
                                                    <p className='text-[14px] font-[500] text-[#323232]'>We collaborate with leading financial institutions to bring you the best solutions and opportunities.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex  gap-[10px] '>
                                                <div>
                                                    <img src={ManeyBagIcon} alt='Money Bag Icon' className='w-[70px] h-[70px]'></img>
                                                </div>
                                                <div>
                                                    <p className='text-[20px] font-[500] text-[#BD7000]'>1,000+ Trusted Partners</p>
                                                    <p className='text-[14px] font-[500] text-[#323232]'>A robust network of partners strengthens our reach and ensures seamless service delivery across the country.</p>
                                                </div>
                                            </div>
                                            <div className='flex items-center gap-[10px] mt-[33px]'>
                                                <div>
                                                    <img src={DirectionIcon} alt='Direction Icon' className='w-[70px] h-[70px]'></img>
                                                </div>
                                                <div>
                                                    <p className='text-[20px] font-[500] text-[#BD7000]'>Pan-India Presence</p>
                                                    <p className='text-[14px] font-[500] text-[#323232]'>From bustling metros to remote towns, KarmVed Bharat is committed to serving every Indian, wherever they are.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div>
                                <div className='px-[200px] xs:px-[20px] sm:px-[20px] mt-[100px]'>
                                    <p className='text-[24px] font-[500] text-[#323232] text-center'>We're Partnered  With The Best</p>
                                    <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] text-center'> That’s why we’ve partnered <span className='text-[#DC9731] '>with industry-leading NBFCs</span></p>
                                </div>
                                <div>
                                    <div>
                                        <div className='grid grid-cols-5 xs:grid-cols-2 sm:grid-cols-2 xs:gap-[20px] sm:gap-[20px] items-center w-[80%] mx-auto mt-[50px]'>
                                            <div>
                                                <img src={TataCapital} alt='Tata Capital Logo' className='mx-auto block'></img>
                                            </div>
                                            <div>
                                                <img src={BajajFinance} alt='Bajaj Finance Logo' className='mx-auto block' ></img>
                                            </div>
                                            <div>
                                                <img src={FibeLogo} alt='Fine Logo' className='mx-auto block' ></img>
                                            </div>
                                            <div>
                                                <img src={MuthootFinance} alt='Muthoot Finance Logo' className='mx-auto block' ></img>
                                            </div>
                                            <div>
                                                <img src={HeroFinacorp} alt='Hero Finacorp' className='mx-auto block' ></img>
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-4 xs:grid-cols-2 sm:grid-cols-2 xs:gap-[20px] sm:gap-[20px] w-[70%] mx-auto mt-[30px]'>
                                            <div>
                                                <img src={LTFinance} alt='L&T Finance' className='mx-auto block' ></img>
                                            </div>
                                            <div>
                                                <img src={StartrdChart} alt='Startd Chart' className='mx-auto block' ></img>
                                            </div>
                                            <div>
                                                <img src={HDFCLoan} alt='HDFC Home Loan' className='mx-auto block' ></img>
                                            </div>
                                            <div>
                                                <img src={Prefr} alt='Prefr Logo' className='mx-auto block' ></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Contactussection/>
                </div>
            </div>
        </div>
    )
}

export default Aboutus