// import React, { useState } from 'react'
// import Commonloginsection from '../../common-log-in-section'
// import { IoPerson } from "react-icons/io5";
// import { IoCall } from "react-icons/io5";
// import { FaMarsStroke, FaVenus } from 'react-icons/fa';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import { toast } from 'react-toastify';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';


// const Profilepage = () => {

//     const navigate = useNavigate();

//     const [genderactive, setGenderactive] = useState("salaried");
//     const user_details = JSON.parse(localStorage.getItem('user'));

//     // Form validation schema using Yup
//     const validationSchema = Yup.object({
//         email: Yup.string().email('Invalid email address').required('Email is required'),
//         loanAmount: Yup.number().positive('Loan amount must be positive').required('Loan amount is required'),
//     });

//     // Formik setup
//     const formik = useFormik({
//         initialValues: {
//             email: '',
//             employmentType: 'salaried',
//             loanAmount: '',
//         },
//         validationSchema,
//         onSubmit: (values) => {

//             console.log(values);

//             const update_id = user_details?._id;

//             if (update_id) {
//                 axios.put("https://karmved-server.onrender.com/otp/update-verify-otps-data/" + update_id, values)
//                     .then((response) => {
//                         console.log(response.data);
//                         formik.resetForm();
//                         toast.success("Profile Details Added Successfully");
//                         navigate("/subscription-plan");
//                         localStorage.removeItem('user');
//                         localStorage.setItem('user_data', JSON.stringify(response?.data?.user));
//                     })
//                     .catch((error) => {
//                         console.error(error);
//                         toast.error(error?.response?.data?.message)
//                     });
//             }

//         },
//     });


//     function activemale() {
//         setGenderactive("salaried");
//         formik.setFieldValue('employmentType', "salaried");
//     }
//     function activefemale() {
//         setGenderactive("self-employed");
//         formik.setFieldValue('employmentType', "self-employed");
//     }


//     return (
//         <div className='main-contanerr'>
//             <div>
//                 <div className='w-[90%] mx-auto grid grid-cols-2 xs:grid-cols-1 sm:grid-cols-1 gap-[20px] mt-[40px]'>
//                     <div>
//                         <Commonloginsection />
//                     </div>
//                     <div>
//                         <div className='w-[100%] bg-[#F3F3F3] py-[50px] rounded-[30px] px-[20px] xs:px-[10px] py-[20px]'>
//                             <div>
//                                 <div className='px-[20px] xs:px-[10px] sm:px-[10px]'>
//                                     <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] text-[#323232] leading-[50px]'>Choose your profile and fill-in details</p>
//                                     <div className='flex gap-[10px] items-center mt-[20px]'>
//                                         <p><IoPerson className='text-[35px] text-[#DC9731] ' /></p>
//                                         <div>
//                                             <p className='text-[14px] font-[500] text-[#323232]'>Full Name:</p>
//                                             <span className='text-[20px] font-[500] text-[#BD7000]'>{user_details?.fullName}</span>
//                                         </div>
//                                     </div>
//                                     <div className='flex gap-[10px] items-center mt-[10px]'>
//                                         <p><IoCall className='text-[35px] text-[#DC9731] ' /></p>
//                                         <div>
//                                             <p className='text-[14px] font-[500] text-[#323232]'>Mobile Number:</p>
//                                             <span className='text-[20px] font-[500] text-[#BD7000]'>{user_details?.mobileNo}</span>
//                                         </div>
//                                     </div>
//                                     {/* Formik Form */}
//                                     <form onSubmit={formik.handleSubmit}>
//                                         <div>
//                                             <p className='text-[18px] font-[500] text-[#49454F] mt-[20px]'>Employment Type</p>
//                                             <div className="flex gap-[10px] mt-[8px]">
//                                                 <button
//                                                     onClick={(e) => {
//                                                         activemale();
//                                                         e.preventDefault();
//                                                     }}
//                                                     className={
//                                                         genderactive === 'salaried'
//                                                             ? 'gap-[10px] bg-[#323232] font-[500] text-[#fff] py-[5px] rounded-[20px] px-[20px]'
//                                                             : 'gap-[10px] bg-[transparent] border border-[#525252] font-[500] text-[#525252] py-[5px] rounded-[20px] px-[20px]'
//                                                     }
//                                                 >
//                                                     <div>
//                                                         <span className="text-[16px] font-[500]">Salaried</span>
//                                                     </div>
//                                                 </button>
//                                                 <button
//                                                     onClick={(e) => {
//                                                         activefemale();
//                                                         e.preventDefault();
//                                                     }}
//                                                     className={
//                                                         genderactive === 'self-employed'
//                                                             ? 'gap-[10px] bg-[#323232] text-[#fff] font-[500] py-[5px] rounded-[20px] px-[20px]'
//                                                             : 'gap-[10px] bg-[transparent] border border-[#525252] font-[500] text-[#525252] py-[5px] rounded-[20px] px-[20px]'
//                                                     }
//                                                 >
//                                                     <div>
//                                                         <span className="text-[14px] font-[500]">Self Employed</span>
//                                                     </div>
//                                                 </button>

//                                             </div>
//                                         </div>

//                                         <div className='mt-[10px]'>
//                                             <label className='text-[16px] font-[500] text-[#49454F]'>Email ID</label><br />
//                                             <input
//                                                 type='email'
//                                                 placeholder='Enter Your email address'
//                                                 className='px-[20px] py-[10px] rounded-[8px] border w-[70%] xs:w-[100%] sm:w-[100%] input-box-shadow'
//                                                 name="email"
//                                                 value={formik.values.email}
//                                                 onChange={formik.handleChange}
//                                                 onBlur={formik.handleBlur}
//                                             />
//                                             {formik.touched.email && formik.errors.email && (
//                                                 <div className="text-red-500 text-xs mt-1">{formik.errors.email}</div>
//                                             )}
//                                         </div>

//                                         <div className='mt-[10px]'>
//                                             <label className='text-[16px] font-[500] text-[#49454F]'>Loan amount</label><br />
//                                             <input
//                                                 type='text'
//                                                 placeholder='Enter your required loan amount'
//                                                 className='px-[20px] py-[10px] rounded-[8px] border w-[70%] xs:w-[100%] sm:w-[100%] input-box-shadow'
//                                                 name="loanAmount"
//                                                 value={formik.values.loanAmount}
//                                                 onChange={formik.handleChange}
//                                                 onBlur={formik.handleBlur}
//                                             />
//                                             {formik.touched.loanAmount && formik.errors.loanAmount && (
//                                                 <div className="text-red-500 text-xs mt-1">{formik.errors.loanAmount}</div>
//                                             )}
//                                         </div>

//                                         <div>
//                                             <button
//                                                 type='submit'
//                                                 className='text-[#563200] text-[16px] bg-[#F2BD70] rounded-[40px] px-[30px] mt-[20px] py-[10px] font-[500]'
//                                             >
//                                                 Continue to Proceed
//                                             </button>
//                                         </div>
//                                     </form>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className='w-[90%] mx-auto mt-[40px] px-[30px] xs:px-[10px] sm:px-[10px]'>
//                     <p className='text-[14px] font-[500] text-center  text-[#525252]'>Loan tenure ranging up to 72 months with Annual Interest Rates ranging between 11% - 36%. Processing fee up to 2%. For Example: Considering a personal loan of Rs.1,00,000 availed at 11%* interest rate for a tenure of 6* years with 2%* processing fee, the APR will be 11.75%*.  </p>
//                     <p className='text-[14px] font-[500] text-center  text-[#525252] mt-[15px]'>*T&C Apply. All these numbers are tentative/indicative, the final loan specifics may vary depending upon the customer profile and NBFCs’ criteria, rules & regulations, and terms & conditions.</p>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Profilepage

import React, { useState } from 'react';
import Commonloginsection from '../../common-log-in-section';
import { IoPerson } from "react-icons/io5";
import { IoCall } from "react-icons/io5";
import { FaMarsStroke, FaVenus } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Profilepage = () => {
    const navigate = useNavigate();

    const [genderactive, setGenderactive] = useState("salaried");
    const user_details = JSON.parse(localStorage.getItem('user'));

    // Form validation schema using Yup
    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email address').required('Email is required'),
        loanAmount: Yup.number().positive('Loan amount must be positive').required('Loan amount is required'),
    });

    // Formik setup
    const formik = useFormik({
        initialValues: {
            email: '',
            employmentType: 'salaried',
            loanAmount: '',
        },
        validationSchema,
        onSubmit: (values) => {

            console.log(values);

            const update_id = user_details?._id;

            if (update_id) {
                axios.put("https://karmved-server.onrender.com/otp/update-verify-otps-data/" + update_id, values)
                    .then((response) => {
                        console.log(response.data);
                        formik.resetForm();
                        toast.success("Profile Details Added Successfully")
                        navigate("/subscription-plan");
                        localStorage.removeItem('user');
                        localStorage.setItem('user_data', JSON.stringify(response?.data?.user));
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error(error?.response?.data?.message)
                    });
            }

        },
    });

    function activemale() {
        setGenderactive("salaried");
        formik.setFieldValue('employmentType', "salaried");
    }
    function activefemale() {
        setGenderactive("self-employed");
        formik.setFieldValue('employmentType', "self-employed");
    }

    return (
        <div className='main-contanerr'>
            <div>
                <div className='w-[90%] mx-auto grid grid-cols-2 xs:grid-cols-1 sm:grid-cols-1 gap-[20px] mt-[40px]'>
                    <div>
                        <Commonloginsection />
                    </div>
                    <div>
                        <div className='w-[100%] bg-[#F3F3F3] py-[50px] rounded-[30px] px-[20px] xs:px-[10px] py-[20px]'>
                            <div>
                                <div className='px-[20px] xs:px-[10px] sm:px-[10px]'>
                                    <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] text-[#323232] leading-[50px]'>
                                        Choose your profile and fill-in details
                                    </p>
                                    <div className='flex gap-[10px] items-center mt-[20px]'>
                                        <p><IoPerson className='text-[35px] text-[#DC9731]' /></p>
                                        <div>
                                            <p className='text-[14px] font-[500] text-[#323232]'>Full Name:</p>
                                            <span className='text-[20px] font-[500] text-[#BD7000]'>{user_details?.fullName}</span>
                                        </div>
                                    </div>
                                    <div className='flex gap-[10px] items-center mt-[10px]'>
                                        <p><IoCall className='text-[35px] text-[#DC9731]' /></p>
                                        <div>
                                            <p className='text-[14px] font-[500] text-[#323232]'>Mobile Number:</p>
                                            <span className='text-[20px] font-[500] text-[#BD7000]'>{user_details?.mobileNo}</span>
                                        </div>
                                    </div>

                                    {/* Formik Form */}
                                    <form onSubmit={formik.handleSubmit}>
                                        <div>
                                            <p className='text-[18px] font-[500] text-[#49454F] mt-[20px]'>Employment Type</p>
                                            <div className="flex gap-[10px] mt-[8px]">
                                                <button
                                                    onClick={(e) => {
                                                        activemale();
                                                        e.preventDefault();
                                                    }}
                                                    className={
                                                        genderactive === 'salaried'
                                                            ? 'gap-[10px] bg-[#323232] font-[500] text-[#fff] py-[5px] rounded-[20px] px-[20px]'
                                                            : 'gap-[10px] bg-[transparent] border border-[#525252] font-[500] text-[#525252] py-[5px] rounded-[20px] px-[20px]'
                                                    }
                                                >
                                                    <div>
                                                        <span className="text-[16px] font-[500]">Salaried</span>
                                                    </div>
                                                </button>
                                                <button
                                                    onClick={(e) => {
                                                        activefemale();
                                                        e.preventDefault();
                                                    }}
                                                    className={
                                                        genderactive === 'self-employed'
                                                            ? 'gap-[10px] bg-[#323232] text-[#fff] font-[500] py-[5px] rounded-[20px] px-[20px]'
                                                            : 'gap-[10px] bg-[transparent] border border-[#525252] font-[500] text-[#525252] py-[5px] rounded-[20px] px-[20px]'
                                                    }
                                                >
                                                    <div>
                                                        <span className="text-[14px] font-[500]">Self Employed</span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>

                                        <div className='mt-[10px]'>
                                            <label className='text-[16px] font-[500] text-[#49454F]'>Email ID</label><br />
                                            <input
                                                type='email'
                                                placeholder='Enter Your email address'
                                                className='px-[20px] py-[10px] rounded-[8px] border w-[70%] xs:w-[100%] sm:w-[100%] input-box-shadow'
                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.email && formik.errors.email && (
                                                <div className="text-red-500 text-xs mt-1">{formik.errors.email}</div>
                                            )}
                                        </div>

                                        <div className='mt-[10px]'>
                                            <label className='text-[16px] font-[500] text-[#49454F]'>Loan amount</label><br />
                                            <input
                                                type='text'
                                                placeholder='Enter your required loan amount'
                                                className='px-[20px] py-[10px] rounded-[8px] border w-[70%] xs:w-[100%] sm:w-[100%] input-box-shadow'
                                                name="loanAmount"
                                                value={formik.values.loanAmount}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.loanAmount && formik.errors.loanAmount && (
                                                <div className="text-red-500 text-xs mt-1">{formik.errors.loanAmount}</div>
                                            )}
                                        </div>

                                        <div>
                                            <button
                                                type='submit'
                                                className='text-[#563200] text-[16px] bg-[#F2BD70] rounded-[40px] px-[30px] mt-[20px] py-[10px] font-[500]'
                                            >
                                                Continue to Proceed
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-[90%] mx-auto mt-[40px] px-[30px] xs:px-[10px] sm:px-[10px]'>
                    <p className='text-[14px] font-[500] text-center text-[#525252]'>
                        Loan tenure ranging up to 72 months with Annual Interest Rates ranging between 11% - 36%. Processing fee up to 2%. For Example: Considering a personal loan of Rs.1,00,000 availed at 11%* interest rate for a tenure of 6* years with 2%* processing fee, the APR will be 11.75%*.
                    </p>
                    <p className='text-[14px] font-[500] text-center text-[#525252] mt-[15px]'>
                        *T&C Apply. All these numbers are tentative/indicative, the final loan specifics may vary depending upon the customer profile and NBFCs’ criteria, rules & regulations, and terms & conditions.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Profilepage;