import React from 'react'
import Contactussection from '../contact-us-section'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Disclaimer = () => {

    const notify = () => toast.success("Toast is working!");

    return (
        <div className=''>
            <div>
                <div>
                    <div>
                        <div className='w-[90%] mx-auto mt-[50px]'>
                            <div>
                                <p className='text-[18px] font-[400] text-[#525252] mt-[15px]'>Karmved Bharat and its customers and employees use and present www.karmvedbharat.com (the “Website”) for personal and informational purposes only. In addition, we further expressly disclaim any warranties or representations (expressed or implied) in respect of quality, suitability, accuracy, reliability, completeness, timeliness, performance for a particular purpose or legality of the services listed or displayed or transacted or the content on the website. You must not perceive and construe any such information or other material as legal, tax, investment, financial, or other advice. You completely acknowledge and undertake that you are accessing the services on the Karmved Bharat website and transacting at your own risk only and are using your best and prudent judgement before entering into and making any transactions through the website. You alone assume the sole responsibility of evaluating the merits and risks associated with the use of any information or other Content contained on the Karmved Bharat Website before making any decisions based on such information or other Content.</p>
                                <p className='text-[18px] font-[400] text-[#525252] mt-[15px]'>Nothing contained on our Website constitutes a solicitation, recommendation, endorsement, or offer by Karmved Bharat to buy or sell any securities or other financial instruments in this or in any other jurisdiction in which such solicitation or offer would be unlawful under the securities laws of such jurisdiction. You further acknowledge that at no time shall any right, title or interest in the services sold through or displayed on the website vest with Karmved Bharat nor shall Karmved Bharat have any obligations or liabilities in respect of any transactions on the website.</p>
                                <p className='text-[18px] font-[400] text-[#525252] mt-[15px]'>After you enter your details on our website for any purpose, the company takes no responsibility in case you come across instances of data misusage of any form.</p>
                            </div>
                        </div>
                        <div>
                            <Contactussection />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Disclaimer