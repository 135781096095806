import React from 'react'
import Contactussection from './contact-us-section'

const TreamsCondition = () => {
    return (
        <div className='main-contanerr'>
            <div>
                <div>
                    <div>
                        <div className='w-[90%] mx-auto mt-[50px]'>
                            <p className='text-[20px] font-[500] text-[#525252]'>Welcome to KarmVed Bharat! By accessing or using our website, mobile application, or services (hereafter referred to as the "Service"), you agree to comply with and be bound by the following terms and conditions ("Terms"). Please read them carefully before using our platform.</p>
                            <div className='mt-[10px]'>
                                <p className='text-[32px] font-[500] text-[#525252] mt-[10px]'>1. Acceptance of Terms</p>
                                <ul className='list-disc mt-[5px] ml-[10px] list-inside'>
                                    <li className='text-[20px] font-[500] text-[#525252]'>By using the Service, you agree to be bound by these Terms, along with our Privacy Policy. If you do not agree to these Terms, you must not access or use the Service.</li>
                                </ul>
                                <p className='text-[32px] font-[500] text-[#525252] mt-[10px]'>2. Eligibility</p>
                                <ul className='list-disc mt-[5px] ml-[10px] list-inside'>
                                    <li className='text-[20px] font-[500] text-[#525252]'>You must be at least 21 years old or have the permission of a parent/guardian to use the Service.</li>
                                    <li className='text-[20px] font-[500] text-[#525252]'>By accessing the Service, you represent and warrant that you have the legal capacity to enter into this agreement.</li>
                                </ul>
                                <p className='text-[32px] font-[500] text-[#525252] mt-[10px]'>3. Use of the Service</p>
                                <ul className='list-disc mt-[5px] ml-[10px] list-inside'>
                                    <li className='text-[20px] font-[500] text-[#525252]'>You agree to use the Service only for lawful purposes and in compliance with all applicable laws and regulations.</li>
                                    <li className='text-[20px] font-[500] text-[#525252]'>You must not engage in any activity that may harm, disrupt, or damage the Service or its users.</li>
                                    <li className='text-[20px] font-[500] text-[#525252]'>Unauthorized use, including but not limited to hacking, data mining, or reverse engineering, is strictly prohibited.</li>
                                </ul>
                                <p className='text-[32px] font-[500] text-[#525252] mt-[10px]'>4. Intellectual Property Rights</p>
                                <ul className='list-disc mt-[5px] ml-[10px] list-inside'>
                                    <li className='text-[20px] font-[500] text-[#525252]'>All content, trademarks, logos, graphics, and materials available on the Service are the exclusive property of KarmVed Bharat or its licensors.</li>
                                    <li className='text-[20px] font-[500] text-[#525252]'>You may not copy, reproduce, distribute, or create derivative works of any content without prior written consent from KarmVed Bharat.</li>
                                </ul>
                                <p className='text-[32px] font-[500] text-[#525252] mt-[10px]'>5. User Accounts</p>
                                <ul className='list-disc mt-[5px] ml-[10px] list-inside'>
                                    <li className='text-[20px] font-[500] text-[#525252]'>You may be required to create an account to access certain features of the Service.</li>
                                    <li className='text-[20px] font-[500] text-[#525252]'>You are responsible for maintaining the confidentiality of your account credentials.</li>
                                    <li className='text-[20px] font-[500] text-[#525252]'>You agree to notify us immediately in case of unauthorized access or security breaches related to your account.</li>
                                </ul>
                                <p className='text-[32px] font-[500] text-[#525252] mt-[10px]'>6. Prohibited Activities</p>
                                <p className='text-[20px] font-[500] text-[#525252] mt-[4px]'>While using the Service, you agree not to:</p>
                                <ul className='list-disc mt-[5px] ml-[10px] list-inside'>
                                    <li className='text-[20px] font-[500] text-[#525252]'>Engage in fraudulent, abusive, or deceptive activities.</li>
                                    <li className='text-[20px] font-[500] text-[#525252]'>Post or share content that is unlawful, offensive, or violates the rights of others.</li>
                                    <li className='text-[20px] font-[500] text-[#525252]'>Use automated systems, bots, or scraping tools to extract data from the Service.</li>
                                </ul>
                                <p className='text-[32px] font-[500] text-[#525252] mt-[10px]'>7. Third-Party Links</p>
                                <p className='text-[20px] font-[500] text-[#525252]'>Our Service may contain links to third-party websites or services. We do not endorse or assume responsibility for the content, practices, or policies of any third-party websites or services.</p>
                                
                                <p className='text-[32px] font-[500] text-[#525252] mt-[10px]'>8. Limitation of Liability</p>
                                <p className='text-[20px] font-[500] text-[#525252]'>To the fullest extent permitted by law, KarmVed Bharat will not be liable for any indirect, incidental, consequential, or punitive damages arising out of your use of the Service, including but not limited to loss of data, revenue, or business opportunities.</p>

                                <p className='text-[32px] font-[500] text-[#525252] mt-[10px]'>9. Indemnification</p>
                                <p className='text-[20px] font-[500] text-[#525252]'>You agree to indemnify, defend, and hold KarmVed Bharat, its affiliates, officers, directors, employees, and agents harmless from and against any claims, liabilities, damages, losses, or expenses arising out of your violation of these Terms or misuse of the Service.</p>

                                <p className='text-[32px] font-[500] text-[#525252] mt-[10px]'>10. Termination</p>
                                <ul className='list-disc mt-[5px] ml-[10px] list-inside'>
                                    <li className='text-[20px] font-[500] text-[#525252]'>We reserve the right to terminate or suspend your access to the Service at any time without prior notice, for any reason, including breach of these Terms.</li>
                                    <li className='text-[20px] font-[500] text-[#525252]'>Upon termination, all provisions of these Terms that should reasonably survive will remain in effect.</li>
                                </ul>

                                <p className='text-[32px] font-[500] text-[#525252] mt-[10px]'>11. Governing Law</p>
                                <ul className='list-disc mt-[5px] ml-[10px] list-inside'>
                                    <li className='text-[20px] font-[500] text-[#525252]'>These Terms shall be governed by and construed in accordance with the laws of India. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in Surat, Gujarat.</li>
                                </ul>

                                <p className='text-[32px] font-[500] text-[#525252] mt-[10px]'>12. Modifications to Terms</p>
                                <p className='text-[20px] font-[500] text-[#525252]'>We reserve the right to update or modify these Terms at any time. Any changes will be effective immediately upon posting on the Service. Your continued use of the Service after changes have been made constitutes your acceptance of the new Terms.</p>


                                <p className='text-[32px] font-[500] text-[#525252] mt-[10px]'>13. Disclaimer of Warranties</p>
                                <p className='text-[20px] font-[500] text-[#525252]'>The Service is provided "as is" and "as available" without warranties of any kind, either express or implied. We do not guarantee that the Service will be error-free, secure, or uninterrupted.</p>


                                <p className='text-[32px] font-[500] text-[#525252] mt-[10px]'>14. Payment Terms</p>
                                <p className='text-[20px] font-[500] text-[#525252]'>If you purchase any product or service through the Service:</p>
                                <ul className='list-disc mt-[5px] ml-[10px] list-inside'>
                                    <li className='text-[20px] font-[500] text-[#525252]'>All payments must be made in full and in accordance with the pricing and payment terms specified at the time of purchase.</li>
                                    <li className='text-[20px] font-[500] text-[#525252]'>Refunds will be subject to our Refund Policy (if applicable).</li>
                                </ul>


                                <p className='text-[32px] font-[500] text-[#525252] mt-[10px]'>15. Contact Us</p>
                                <p className='text-[20px] font-[500] text-[#525252]'>If you have any questions, concerns, or need further clarification regarding these Terms, feel free to reach out to us: <br/> <a className='text-[#1100FF]' href='mailto:info@karmvedbharat.com'>Email: info@karmvedbharat.com</a></p>
                                <ul className='list-disc mt-[5px] ml-[10px] list-inside'>
                                    <li className='text-[20px] font-[500] text-[#525252]'>All payments must be made in full and in accordance with the pricing and payment terms specified at the time of purchase.</li>
                                    <li className='text-[20px] font-[500] text-[#525252]'>Refunds will be subject to our Refund Policy (if applicable).</li>
                                </ul>
                                <p className='text-[20px] font-[500] text-[#525252] mt-[20px]'>By using KarmVed Bharat, you agree to these Terms and Conditions. Thank you for being a part of our community!</p>
                            </div>
                        </div>
                        <div>
                            <Contactussection/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TreamsCondition