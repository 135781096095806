import React from 'react'
import Commonloginsection from '../../common-log-in-section'
import './../../styleverify.css'
import OTPInput from '../../otp-field'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';

const Otpscreen = () => {

    const navigate = useNavigate();
    const user_details = JSON.parse(localStorage.getItem('user'));

    // Setting up Formik with initial values and validation
    const formik = useFormik({
        initialValues: {
            otp: ''
        },
        validate: (values) => {
            const errors = {};
            if (!values.otp) {
                errors.otp = 'OTP is required';
            } else if (values.otp.length !== 6) {
                errors.otp = 'OTP must be 6 digits';
            }
            return errors;
        },
        onSubmit: (values) => {

            const otp_int = parseInt(values.otp);

            axios.post("https://karmved-server.onrender.com/otp/verify-otp", { mobileNo: user_details?.mobileNo, otp: otp_int })
                .then((response) => {
                    console.log(response.data);
                    formik.resetForm();
                    formik.setFieldValue('otp', '');
                    navigate("/profile-page")
                    toast.success(response?.data?.message)
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error?.response?.data?.message)
                });

        }
    });

    const handleOtpComplete = (otp) => {
        formik.setFieldValue('otp', otp); // Update Formik state with OTP value
    };

    return (
        <div className='main-contanerr'>
            <div>
                <div className='w-[90%] mx-auto grid grid-cols-2 xs:grid-cols-1 sm:grid-cols-1 gap-[20px] mt-[40px]'>
                    <div>
                        <Commonloginsection />
                    </div>
                    <div>
                        <div className='w-[100%] bg-[#F3F3F3] py-[50px] rounded-[30px] px-[20px] xs:px-[10px] py-[20px]'>
                            <div>
                                <div className='px-[50px] xs:px-[10px] sm:px-[10px]'>
                                    <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] text-[#323232] text-center'>Mobile verification</p>
                                    <p className='text-[18px] font-[500] text-[#323232]'>Kindly enter the 6 digit verification code sent to your registered mobile number.</p>
                                    <p className='text-[18px] font-[500] text-[#1100FF] mt-[20px]'>Mobile No: <span> {user_details?.mobileNo} </span></p>
                                </div>
                                <div className='px-[50px] xs:px-[10px] sm:px-[10px]'>
                                    <p className='text-[16px] font-[500] text-[#49454F] '>Enter 6 Digit OTP</p>
                                    <OTPInput length={6} onComplete={handleOtpComplete} />
                                    {/* Displaying Formik validation error */}
                                    {formik.errors.otp && formik.touched.otp && (
                                        <p className='text-red-500 text-sm'>{formik.errors.otp}</p>
                                    )}
                                    <div className='flex justify-between items-center'>
                                        <p className='text-[16px] font-[500] text-[#49454F] mt-[10px]'>Didn’t received the OTP?</p>
                                        <p className='text-[16px] font-[500] text-[#1100FF]'>0:48s</p>
                                    </div>
                                    <button
                                        className='text-[#563200] text-[16px] font-[500] px-[30px] py-[10px] bg-[#F2BD70] rounded-[40px] mt-[20px]'
                                        onClick={formik.handleSubmit}  // Trigger form submission on button click
                                    >
                                        Verify OTP
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-[90%] mx-auto mt-[40px] px-[30px] xs:px-[10px] sm:px-[10px]'>
                    <p className='text-[14px] font-[500] text-center  text-[#525252]'>Loan tenure ranging up to 72 months with Annual Interest Rates ranging between 11% - 36%. Processing fee up to 2%. For Example: Considering a personal loan of Rs.1,00,000 availed at 11%* interest rate for a tenure of 6* years with 2%* processing fee, the APR will be 11.75%*.  </p>
                    <p className='text-[14px] font-[500] text-center  text-[#525252] mt-[15px]'>*T&C Apply. All these numbers are tentative/indicative, the final loan specifics may vary depending upon the customer profile and NBFCs’ criteria, rules & regulations, and terms & conditions.</p>
                </div>
            </div>
        </div>
    )
}

export default Otpscreen