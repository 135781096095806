// import React, { useState } from 'react'
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'

// import TataCapitalLogo from './../../imgs/tata_capital_logo.svg'
// import BajajFinservLogo from './../../imgs/bajaj_finance_logo.svg'
// import FibeLogo from './../../imgs/fibe_logo.svg'
// import MuthootFinance from './../../imgs/muthoot_finance_logo.svg'
// import HeroFinCorp from './../../imgs/hero_fincorp.svg'

// import Commonloginsection from '../../common-log-in-section'

// const Login = () => {
//     const [name, setName] = useState()
//     const [mobile, setMobile] = useState()

//     const handlename = (e) => {
//         setName(e.target.value)
//     }

//     return (
//         <div className='main-contanerr'>
//             <div >
//                 <div className='w-[90%] mx-auto grid grid-cols-2 xs:grid-cols-1 sm:grid-cols-1 gap-[20px] mt-[40px]'>
//                     <div>
//                         <Commonloginsection />
//                     </div>
//                     <div>
//                         <div className='w-[100%] bg-[#F3F3F3] rounded-[30px] px-[20px] py-[20px]'>
//                             <div>
//                                 <div>
//                                     <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] text-[#323232]'>Basic Details</p>
//                                     <p className='text-[18px] font-[500] text-[#323232]'>Start Your Process With Basic Details</p>
//                                 </div>
//                                 <form className='w-[70%] xs:w-[100%] sm:w-[100%]'>

//                                     <div className='mt-[20px]'>
//                                         <label className='text-[#49454F] text-[16px] font-[500]'>Full Name</label><br />
//                                         <input type='text' placeholder='Shah Keven Rakeshbhai' className='px-[20px] py-[10px] rounded-[8px] border w-[100%] xs:w-[100%] sm:w-[100%] input-box-shadow' value={name} onChange={handlename} ></input>
//                                         {/* <p>{name}</p> */}
//                                         <p className='text-[#838383] text-[12px] font-[500] mt-[5px]'>*Enter your full name as per official records</p>
//                                     </div>
//                                     <div className='mt-[20px]'>
//                                         <label className='text-[#49454F] text-[16px] font-[500]'>Mobile Number</label><br />
//                                         <PhoneInput
//                                             country={'in'}
//                                             value={mobile}
//                                             onChange={setMobile}
//                                         />
//                                     </div>
//                                     <div>
//                                         <button type='submit' className='px-[35px] py-[10px] bg-[#F2BD70] text-[#000000] block mt-[30px] rounded-[46px]'>Apply Now</button>
//                                     </div>
//                                 </form>
//                                 <hr className='bg-[#000] mt-[20px]' />
//                                 <div className='mt-[20px]'>
//                                     <p className='text-[16px] font-[500] text-[#525252]'>Loan facility is provided by our NBFC/Lending Partners:</p>
//                                     <div className='grid grid-cols-5 gap-[10px] items-center mt-[15px]'>
//                                         <img src={TataCapitalLogo} alt='Tata Capital Logo'></img>
//                                         <img src={BajajFinservLogo} alt='Bajaj Finance Logo'></img>
//                                         <img src={FibeLogo} alt='Fibe Logo'></img>
//                                         <img src={MuthootFinance} alt='Mhuthoot Finance Logo'></img>
//                                         <img src={HeroFinCorp} alt='Hero Fincorp Logo'></img>
//                                     </div>
//                                 </div>
//                                 <hr className='bg-[#000] mt-[20px]' />
//                                 <div>
//                                     <div>
//                                         <p className='text-[14px] font-[500] mt-[20px] text-[#323232]'>By submitting the form & proceeding, you agree to the <span className='font-[800] text-[#BD7000]'>Terms of Use</span> and <span className='font-[800] text-[#BD7000]'>Privacy Policy</span> of KarmVed Bharat.</p>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className='w-[90%] mx-auto mt-[40px] px-[30px] xs:px-[10px] sm:px-[10px]'>
//                     <p className='text-[14px] font-[500] text-center  text-[#525252]'>Loan tenure ranging up to 72 months with Annual Interest Rates ranging between 11% - 36%. Processing fee up to 2%. For Example: Considering a personal loan of Rs.1,00,000 availed at 11%* interest rate for a tenure of 6* years with 2%* processing fee, the APR will be 11.75%*.  </p>
//                     <p className='text-[14px] font-[500] text-center  text-[#525252] mt-[15px]'>*T&C Apply. All these numbers are tentative/indicative, the final loan specifics may vary depending upon the customer profile and NBFCs’ criteria, rules & regulations, and terms & conditions.</p>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Login


import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import TataCapitalLogo from './../../imgs/tata_capital_logo.svg';
import BajajFinservLogo from './../../imgs/bajaj_finance_logo.svg';
import FibeLogo from './../../imgs/fibe_logo.svg';
import MuthootFinance from './../../imgs/muthoot_finance_logo.svg';
import HeroFinCorp from './../../imgs/hero_fincorp.svg';

import Commonloginsection from '../../common-log-in-section';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Bounce, ToastContainer, toast } from 'react-toastify';

const Login = () => {
    const [mobileNo, setmobileNo] = useState('');
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            fullName: '',
            mobileNo: mobileNo
        },
        validationSchema: Yup.object({
            fullName: Yup.string()
                .required('Full name is required')
                .matches(/^[A-Za-z\s]+$/, 'Full name must contain only letters'),
            mobileNo: Yup.string()
                .required('Mobile number is required')
                .min(13, 'Mobile number must be at least 10 digits')
        }),
        onSubmit: (values) => {
            axios.post("https://karmved-server.onrender.com/otp/send-otp", values)
                .then((response) => {
                    console.log(response.data);
                    formik.resetForm();
                    navigate('/verify');
                    localStorage.setItem('user', JSON.stringify(response?.data?.user));
                    toast.success(response?.data?.message)
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error?.response?.data?.message)
                });
        }
    });

    const handlePhoneChange = (value) => {
        // Manually adding the "+" symbol and storing the phone number
        if (value.charAt(0) !== '+') {
            value = `+${value}`;
        }
        setmobileNo(value);
        formik.setFieldValue('mobileNo', value);
    };

    return (
        <div className="main-contanerr">
            <div>
                <div className="w-[90%] mx-auto grid grid-cols-2 xs:grid-cols-1 sm:grid-cols-1 gap-[20px] mt-[40px]">
                    <div>
                        <Commonloginsection />
                    </div>
                    <div>
                        <div className="w-[100%] bg-[#F3F3F3] rounded-[30px] px-[20px] py-[20px]">
                            <div>
                                <div>
                                    <p className="text-[48px] xs:text-[40px] sm:text-[40px] font-[900] text-[#323232]">
                                        Basic Details
                                    </p>
                                    <p className="text-[18px] font-[500] text-[#323232]">Start Your Process With Basic Details</p>
                                </div>
                                <div className="mt-[20px]">
                                    <form onSubmit={formik.handleSubmit} className='w-[70%] xs:w-[100%] sm:w-[100%]'>
                                        <div>
                                            <label className="text-[#49454F] text-[16px] font-[500]">Full Name</label><br />
                                            <input
                                                type="text"
                                                name="fullName"
                                                placeholder="Shah Keven Rakeshbhai"
                                                className="px-[20px] py-[10px] rounded-[8px] border w-[100%] xs:w-[100%] sm:w-[100%] input-box-shadow"
                                                value={formik.values.fullName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.fullName && formik.errors.fullName ? (
                                                <div className="text-red-500 text-[12px]">{formik.errors.fullName}</div>
                                            ) : null}
                                            <p className="text-[#838383] text-[12px] font-[500] mt-[5px]">*Enter your full name as per official records</p>
                                        </div>
                                        <div className="mt-[20px]">
                                            <label className="text-[#49454F] text-[16px] font-[500]">Mobile Number</label><br />
                                            <PhoneInput
                                                country="in"
                                                value={mobileNo}
                                                onChange={handlePhoneChange}
                                                inputClass="px-[20px] py-[10px] rounded-[8px] border w-[70%] xs:w-[100%] sm:w-[100%] input-box-shadow"
                                            />
                                            {formik.touched.mobileNo && formik.errors.mobileNo ? (
                                                <div className="text-red-500 text-[12px]">{formik.errors.mobileNo}</div>
                                            ) : null}
                                        </div>
                                        <div>
                                            <button
                                                type="submit"
                                                className="px-[35px] py-[10px] bg-[#F2BD70] text-[#000000] block mt-[30px] rounded-[46px]"
                                            >
                                                Apply Now
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <hr className="bg-[#000] mt-[20px]" />
                                <div className="mt-[20px]">
                                    <p className="text-[16px] font-[500] text-[#525252]">
                                        Loan facility is provided by our NBFC/Lending Partners:
                                    </p>
                                    <div className="grid grid-cols-5 gap-[10px] items-center mt-[15px]">
                                        <img src={TataCapitalLogo} alt="Tata Capital Logo" />
                                        <img src={BajajFinservLogo} alt="Bajaj Finance Logo" />
                                        <img src={FibeLogo} alt="Fibe Logo" />
                                        <img src={MuthootFinance} alt="Muthoot Finance Logo" />
                                        <img src={HeroFinCorp} alt="Hero Fincorp Logo" />
                                    </div>
                                </div>
                                <hr className="bg-[#000] mt-[20px]" />
                                <div>
                                    <div>
                                        <p className="text-[14px] font-[500] mt-[20px] text-[#323232]">
                                            By submitting the form & proceeding, you agree to the{' '}
                                            <span className="font-[800] text-[#BD7000]">Terms of Use</span> and{' '}
                                            <span className="font-[800] text-[#BD7000]">Privacy Policy</span> of KarmVed Bharat.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-[90%] mx-auto mt-[40px] px-[30px] xs:px-[10px] sm:px-[10px]">
                    <p className="text-[14px] font-[500] text-center text-[#525252]">
                        Loan tenure ranging up to 72 months with Annual Interest Rates ranging between 11% - 36%. Processing fee up to 2%. For Example: Considering a personal loan of Rs.1,00,000 availed at 11%* interest rate for a tenure of 6* years with 2%* processing fee, the APR will be 11.75%*.
                    </p>
                    <p className="text-[14px] font-[500] text-center text-[#525252] mt-[15px]">
                        *T&C Apply. All these numbers are tentative/indicative, the final loan specifics may vary depending upon the customer profile and NBFCs’ criteria, rules & regulations, and terms & conditions.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;