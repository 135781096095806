import React from "react";
import { FaChevronRight, FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import FooterLogo from './../imgs/karmved_logo.svg'

function Footer() {
    return (
        <div className="main-contanerr">
            <div>
                <div>
                    <div>
                        {/* <div className='contact-info-main w-[90%] mx-auto rounded-[30px] mt-[100px]'>
                            <div className='contact-us-main  py-[30px] px-[50px] xs:px-[15px] sm:px-[10px] rounded-[30px]'>
                                <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] leading-[50px] text-[#fff]'>Have more questions?</p>
                                <p className='text-[18px] font-[500] text-[#fff] mt-[10px]'>We got you! Get quick resolutions to your concerns</p>
                                <div className='flex  gap-[20px] items-center mt-[80px]'>
                                    <button className='flex bg-[#DC9731] px-[20px] py-[15px] rounded-[30px] text-[16px] font-[500] text-[#fff] items-center gap-[10px]'>Contact Us <FaChevronRight /></button>
                                    <p className='text-[#F2BD70] text-[16px] font-[500] mb-[0px]'>Learn More About</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className='flex xs:block sm:block justify-between w-[90%] py-[20px] mx-auto mt-[60px]'>
                        <div>
                            <img src={FooterLogo} alt='Karmaved Bharat Footer Logo' className='xs:block xs:mx-auto sm:block sm:mx-auto'></img>
                        </div>
                        <div className='flex xs:mt-[10px] sm:mt-[10px] xs:block sm:block gap-[20px] items-center'>
                            <div className='flex xs:grid xs:grid-cols-2 sm:grid sm:grid-cols-3 xs:justify-center sm:justify-center gap-[10px] items-center'>
                                <a href="/privacy-policy"><p className='text-[14px] font-[500] text-[#323232] hover:text-[#BD7000] hover:transition hover:ease-in'>Privacy policy</p></a>
                                <a href="/terms-condition"><p className='text-[14px] font-[500] text-[#323232] hover:text-[#BD7000] hover:transition hover:ease-in'>Terms & Conditions</p></a>
                                <a href="/refund-policy"><p className='text-[14px] font-[500] text-[#323232] hover:text-[#BD7000] hover:transition hover:ease-in'>Cancellation & Refund Policy</p></a>
                                <a href="/disclaimer"><p className='text-[14px] font-[500] text-[#323232] hover:text-[#BD7000] hover:transition hover:ease-in'>Disclaimer</p></a>
                            </div>
                            {/* <div className='flex xs:mt-[10px] xs:justify-center sm:justify-center sm:mt-[10px] gap-[10px] items-center'>
                                <span className='text-[25px]'><FaLinkedin /></span>
                                <span className='text-[25px]'><FaFacebook /></span>
                                <span className='text-[25px]'><FaYoutube /></span>
                                <span className='text-[25px]'><FaInstagram /></span>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
