import React, { useState } from 'react'
import RightTickIcon from './../../imgs/right-tick-icon.svg'
import ProfiteIcon from './../../imgs/profit_icon.svg'
import DocumentIcon from './../../imgs/document_icon.svg'
import BankIcon from './../../imgs/bank_icon.svg'
import ManeyBagIcon from './../../imgs/money-bag.svg'
import DirectionIcon from './../../imgs/directions.svg'
import GrowthIcon from './../../imgs/growth_icon.svg'
import CarrerIcon from './../../imgs/career_icon.svg'
import CustomerService from './../../imgs/costumer_support_icon.svg'
import PieChartIcon from './../../imgs/pie-chart-icon.svg'
import WorkIcon from './../../imgs/work_icon.svg'

import TataCapital from './../../imgs/tata_capital_logo.svg'
import BajajFinance from './../../imgs/bajaj_finance_logo.svg'
import FibeLogo from './../../imgs/fibe_logo.svg'
import MuthootFinance from './../../imgs/muthoot_finance_logo.svg'
import HeroFinacorp from './../../imgs/hero_fincorp.svg'
import LTFinance from './../../imgs/lnt_finance.svg'
import StartrdChart from './../../imgs/Standard_Chartered.svg'
import HDFCLoan from './../../imgs/hdfc_home_loan.svg'
import Prefr from './../../imgs/prefr_logo.svg'

import FAQ from './../../faq'

import Contactussection from '../contact-us-section'

const Channelpartner = () => {

    const [faqs, setFaqs] = useState([
        {
            question: " What are the advantages of being a KarmVed Bharat Channel Partner?",
            answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pharetra lorem eu dolor rhoncus, at scelerisque ligula gravida. Sed porta id mi sit amet convallis. Etiam iaculis massa sit amet lacus blandit sodales. Nulla ultrices velit a diam placerat congue. Pellentesque iaculis, ipsum quis eleifend dapibus, est dui eleifend ante, quis fermentum mi ligula quis nisl. Ut et ex dui. Integer id venenatis quam.",
            open: true
        },
        {
            question: "How can I join the KarmVed Bharat Channel Partner Program?",
            answer: "You! The viewer!",
            open: false
        },
        {
            question:
                "Why should I join the KarmVed Bharat Channel Partner Program?",
            answer: "KarmVed Bharat is one of India’s fastest-growing financial platforms. Our Channel Partner Program stands out as a highly rewarding B2B affiliate model, offering unbeatable commissions. Plus, you’ll have the opportunity to provide seamless, instant financial solutions to your customers—enhancing their experience while boosting your earnings.",
            open: false
        },
        {
            question:
                "How much can I earn as a Channel Partner?",
            answer: "Any Indian citizen residing in India, above the age of 18, with a proof of current employment, earning a monthly salary of Rs.15,000 and above, a bank account, and a valid identity and address proof can apply.",
            open: false
        },
        {
            question:
                "How can I track my earnings?",
            answer: "Any Indian citizen residing in India, above the age of 18, with a proof of current employment, earning a monthly salary of Rs.15,000 and above, a bank account, and a valid identity and address proof can apply.",
            open: false
        }
    ]);

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };

    return (
        <div className='main-contanerr'>
            <div>
                <div>
                    <div>
                        <div className='w-[90%] mx-auto rounded-[30px] channel-partner-info-main'>
                            <div className='px-[60px] py-[80px] xs:px-[20px] sm:px-[20px] rounded-[30px] mt-[22px] subscripsion-plan-info'>
                                <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] w-[70%] xs:w-[100%] sm:w-[100%] leading-[50px] text-[#fff]'>{`Your Background Doesn’t Matter – Your Passion Does!`}</p>
                                <p className='text-[18px] font-[500] w-[70%] xs:w-[100%] sm:w-[100%] mt-[45px] text-[#fff]'>Looking for a way to generate additional income or start your own business? Whether you're a salaried individual, a homemaker, a student, or simply someone with big dreams, KarmVed Bharat’s Refer & Earn model is your golden opportunity!</p>
                                <p className='text-[18px] font-[500] w-[70%] xs:w-[100%] sm:w-[100%] mt-[20px] text-[#fff]'>You can become a Channel Partner and unlock the potential to earn up to ₹3 Lakhs per month. All you need is the drive to succeed, and we’ll provide you with the platform to make it happen.</p>
                            </div>
                        </div>

                        <div className='w-[90%] mx-auto mt-[80px]'>
                            <div>
                                <p className='text-[48px] xs:text-[40px] sm:text-[40px]  font-[900] leading-[50px] text-[#323232]'>Who Can Join?</p>
                                <div>
                                    <div className='grid grid-cols-3 xs:grid-cols-1 sm:grid-cols-1 gap-[40px] mt-[60px] w-[90%]'>
                                        <div className='flex gap-[10px] items-start'>
                                            <div>
                                                <img src={RightTickIcon} alt='Right Tick Icon'></img>
                                            </div>
                                            <div>
                                                <p className='text-[20px] font-[500] leading-[15px] text-[#BD7000]'>Aspiring Entrepreneurs</p>
                                                <p className='text-[14px] font-[500] text-[#323232] mt-[5px]'>Kickstart your journey to business success with zero hassle.</p>
                                            </div>
                                        </div>
                                        <div className='flex gap-[10px] items-start'>
                                            <div>
                                                <img src={RightTickIcon} alt='Right Tick Icon'></img>
                                            </div>
                                            <div>
                                                <p className='text-[20px] font-[500] leading-[15px] text-[#BD7000]'>Self-Employed Professionals</p>
                                                <p className='text-[14px] font-[500] text-[#323232] mt-[5px]'>Diversify your income stream and boost your financial growth.</p>
                                            </div>
                                        </div>
                                        <div className='flex gap-[10px] items-start'>
                                            <div>
                                                <img src={RightTickIcon} alt='Right Tick Icon'></img>
                                            </div>
                                            <div>
                                                <p className='text-[20px] font-[500] leading-[15px] text-[#BD7000]'>Students</p>
                                                <p className='text-[14px] font-[500] text-[#323232] mt-[5px]'>Start early with a digital business that lets you earn while you learn.</p>
                                            </div>
                                        </div>
                                        <div className='flex gap-[10px] items-start'>
                                            <div>
                                                <img src={RightTickIcon} alt='Right Tick Icon'></img>
                                            </div>
                                            <div>
                                                <p className='text-[20px] font-[500] leading-[15px] text-[#BD7000]'>Salaried Individuals</p>
                                                <p className='text-[14px] font-[500] text-[#323232] mt-[5px]'>Add a steady side income to your existing earnings.</p>
                                            </div>
                                        </div>
                                        <div className='flex gap-[10px] items-start'>
                                            <div>
                                                <img src={RightTickIcon} alt='Right Tick Icon'></img>
                                            </div>
                                            <div>
                                                <p className='text-[20px] font-[500] leading-[15px] text-[#BD7000]'>Homemakers</p>
                                                <p className='text-[14px] font-[500] text-[#323232] mt-[5px]'>Turn your time at home into a thriving business opportunity.</p>
                                            </div>
                                        </div>
                                        <div className='flex gap-[10px] items-start'>
                                            <div>
                                                <img src={RightTickIcon} alt='Right Tick Icon'></img>
                                            </div>
                                            <div>
                                                <p className='text-[20px] font-[500] leading-[15px] text-[#BD7000]'>Anyone</p>
                                                <p className='text-[14px] font-[500] text-[#323232] mt-[5px]'>If you’re ready to build something meaningful and successful, this is for you!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <a href='/log-in'><button className='px-[40px] py-[10px] text-[#ffffff] bg-[#F2BD70] rounded-[46px] text-[16px] font-[500] mt-[50px]'>Apply Now</button></a>
                                </div>
                            </div>
                        </div>


                        <div>
                            <div>
                                <div>
                                    <div className='aapka-vishvas-main py-[70px] rounded-[30px] border border-[#DC973130] mt-[100px] w-[90%] mx-auto'>
                                        <div className='mt-[20px]'>
                                            <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] w-[70%] xs:w-[90%] sm:w-[90%] mx-auto text-center'>Channel partner plan – packed with perks to propel your success!</p>
                                            <p className='text-[18px] font-[500] mt-[20px] text-[#323232] w-[70%] xs:w-[90%] sm:w-[90%] text-center mx-auto'>Whether you’re looking for a side hustle, career shift, or entrepreneurial venture, KarmVed Bharat’s Channel Partner Plan offers everything you need to thrive.</p>
                                        </div>
                                        <div className='flex xs:block sm:block w-[70%] xs:w-[90%] sm:w-[90%] mx-auto gap-[40px] mt-[40px] '>
                                            <div>
                                                <div className='flex items-start xs:items-start sm:items-start gap-[10px] '>
                                                    <div>
                                                        <img src={ProfiteIcon} alt='Profite Icon' className='w-[80px] h-[80px]'></img>
                                                    </div>
                                                    <div>
                                                        <p className='text-[20px] font-[500] text-[#BD7000]'>Earn up to ₹3,00,000/Month</p>
                                                        <p className='text-[14px] font-[500] text-[#323232]'>Tap into a lucrative business model with limitless growth potential. Earn handsomely through our streamlined Refer & Earn venture.</p>
                                                    </div>
                                                </div>
                                                <div className='flex items-center xs:items-start sm:items-start  gap-[10px] mt-[33px]'>
                                                    <div>
                                                        <img src={GrowthIcon} alt='Growth Icon' className='w-[80px] h-[80px]'></img>
                                                    </div>
                                                    <div>
                                                        <p className='text-[20px] font-[500] text-[#BD7000]'>High Profits, Low Investment</p>
                                                        <p className='text-[14px] font-[500] text-[#323232]'>Rarely does such a rewarding opportunity come with minimal investment. Start your journey today and begin generating income instantly!</p>
                                                    </div>
                                                </div>
                                                <div className='flex items-center xs:items-start sm:items-start  gap-[10px] mt-[33px]'>
                                                    <div>
                                                        <img src={CarrerIcon} alt='Carrer    Icon' className='w-[80px] h-[80px]'></img>
                                                    </div>
                                                    <div>
                                                        <p className='text-[20px] font-[500] text-[#BD7000]'>A Lifetime Career Opportunity</p>
                                                        <p className='text-[14px] font-[500] text-[#323232]'>This isn’t just a side hustle—it’s a career pathyou can rely on for long-term financial  success and growth.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='flex items-center xs:items-start sm:items-start gap-[10px] xs:mt-[30px] sm:mt-[30px]'>
                                                    <div>
                                                        <img src={CustomerService} alt='Customer Service Icon' className='w-[80px] h-[80px]'></img>
                                                    </div>
                                                    <div>
                                                        <p className='text-[20px] font-[500] text-[#BD7000]'>Free Marketing Support</p>
                                                        <p className='text-[14px] font-[500] text-[#323232]'>With expert marketing assistance provided by KarmVed Bharat, your business will be poised for substantial growth.</p>
                                                    </div>
                                                </div>
                                                <div className='flex items-center xs:items-start sm:items-start gap-[10px] mt-[33px]'>
                                                    <div>
                                                        <img src={PieChartIcon} alt='Pie Chart Icon' className='w-[80px] h-[80px]'></img>
                                                    </div>
                                                    <div>
                                                        <p className='text-[20px] font-[500] text-[#BD7000]'>Personal Revenue Tracking Portal</p>
                                                        <p className='text-[14px] font-[500] text-[#323232]'>Gain full transparency with your own digital dashboard to track earnings, monitor referrals, and stay informed.</p>
                                                    </div>
                                                </div>
                                                <div className='flex items-center xs:items-start sm:items-start gap-[10px] mt-[33px]'>
                                                    <div>
                                                        <img src={WorkIcon} alt='Work Icon' className='w-[80px] h-[80px]'></img>
                                                    </div>
                                                    <div>
                                                        <p className='text-[20px] font-[500] text-[#BD7000]'>Completely Digital Business </p>
                                                        <p className='text-[14px] font-[500] text-[#323232]'>Say goodbye to infrastructure hassles! Our 100% digital process lets you run your business efficiently from anywhere.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div>
                            <div>
                                <div className='px-[200px] xs:px-[20px] sm:px-[20px] mt-[100px]'>
                                    <p className='text-[24px] font-[500] text-[#323232] text-center'>We're Partnered  With The Best</p>
                                    <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] text-center'> That’s why we’ve partnered <span className='text-[#DC9731] '>with industry-leading NBFCs</span></p>
                                </div>
                                <div>
                                    <div>
                                        <div className='grid grid-cols-5 xs:grid-cols-2 sm:grid-cols-2 xs:gap-[20px] sm:gap-[20px] items-center w-[80%] mx-auto mt-[50px]'>
                                            <div>
                                                <img src={TataCapital} alt='Tata Capital Logo' className='mx-auto block'></img>
                                            </div>
                                            <div>
                                                <img src={BajajFinance} alt='Bajaj Finance Logo' className='mx-auto block' ></img>
                                            </div>
                                            <div>
                                                <img src={FibeLogo} alt='Fine Logo' className='mx-auto block' ></img>
                                            </div>
                                            <div>
                                                <img src={MuthootFinance} alt='Muthoot Finance Logo' className='mx-auto block' ></img>
                                            </div>
                                            <div>
                                                <img src={HeroFinacorp} alt='Hero Finacorp' className='mx-auto block' ></img>
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-4 xs:grid-cols-2 sm:grid-cols-2 xs:gap-[20px] sm:gap-[20px] w-[70%] mx-auto mt-[30px]'>
                                            <div>
                                                <img src={LTFinance} alt='L&T Finance' className='mx-auto block' ></img>
                                            </div>
                                            <div>
                                                <img src={StartrdChart} alt='Startd Chart' className='mx-auto block' ></img>
                                            </div>
                                            <div>
                                                <img src={HDFCLoan} alt='HDFC Home Loan' className='mx-auto block' ></img>
                                            </div>
                                            <div>
                                                <img src={Prefr} alt='Prefr Logo' className='mx-auto block' ></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div>
                            <div>
                                <div className='w-[90%] mx-auto mt-[100px]'>
                                    <div>
                                        <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] leading-[50px]'>Frequently asked questions</p>
                                        <p className='text-[18px] font-[500] mt-[25px]'>Access answers to common queries in our FAQs</p>
                                    </div>
                                    <div className="faqs xs:w-[90%] sm:w-[90%] mt-[60px]">
                                        {faqs.map((faq, index) => (
                                            <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <Contactussection/>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default Channelpartner