import React from 'react'
import Header from '../../header'
import Footer from '../footer'
import { Outlet } from 'react-router-dom'

const Layout = () => {
    return (
        <div>
            <div>
                <div>
                    <Header />
                    <Outlet />
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Layout