import React, { useState } from "react";
import { MdVerified } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io"; // Import chevron icon
import KarmvedFinance from "./imgs/karmved_logo.svg";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoanDropdownOpen, setIsLoanDropdownOpen] = useState(false);
  const [isLoanCalculatorOpen, setIsLoanCalculatorOpen] = useState(false);
  const [mobileDropdown, setMobileDropdown] = useState(null); // Track which dropdown is open in mobile view

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleCompanyDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleLoanDropdown = () => {
    setIsLoanDropdownOpen(!isLoanDropdownOpen);
  };

  const toggleLoanCalcDropdown = () => {
    setIsLoanCalculatorOpen(!isLoanCalculatorOpen);
  };


  const toggleMobileDropdown = (dropdown) => {
    setMobileDropdown(mobileDropdown === dropdown ? null : dropdown);
  };

  return (
    <header className="bg-gray-50 shadow-md main-contanerr">
      {/* Top Bar */}
      {/* <div className="py-1 px-4 text-xs text-center flex flex-col sm:flex-row justify-between items-center MainColor"> */}
      {/* <div className="flex xs:block sm:block xs:mx-auto sm:mx-auto justify-between px-[20px] py-[10px] border border-b-[#DC973138] bg-[#DC973138]">
        <div className="mb-2 sm:mb-0 flex  items-center justify-center">
          <MdVerified className="mr-1 text-lg text-[#BD7000] xs:hidden sm:hidden" />
          <span className="font-semibold text-[#BD7000] xs:text-[10px] sm:text-[10px] xs:text-center sm:text-center">
            Karmved Bharat Finance - is a non-deposit taking NBFC-MFI registered
            with the RBI.
          </span>
        </div>
        <div className="font-semibold text-[#BD7000] xs:text-[10px] sm:text-[10px] xs:text-center sm:text-center">
          2024 © KarmVed Bharat Finverse Service India Pvt. Ltd.
        </div>
      </div> */}

      {/* Main Navigation */}
      <nav className="bg-white">
        <div className="max-w-screen-xl mx-auto flex justify-between items-center p-4">
          {/* Left Side: Logo and Navigation */}
          <div className="flex items-center space-x-8">
            {/* Logo */}
            <div className="text-orange-700 font-bold text-xl flex items-center space-x-2">
              <a href="/"><img
                src={KarmvedFinance}
                alt="Karmved Finance"
                className="h-10 w-55"
              /></a>
            </div>
            {/* Desktop Navigation */}
            <div className="hidden xl:flex 2xl:flex lg:flex space-x-8">
              {/* Company Dropdown */}
              <div
                className="relative"
                onMouseEnter={toggleCompanyDropdown}
                onMouseLeave={toggleCompanyDropdown}
              >
                <span className="flex items-center text-gray-700 hover:text-orange-500 cursor-pointer">
                  Company
                  <IoIosArrowDown
                    className={`ml-1 transition-transform ${isDropdownOpen ? "rotate-180" : ""
                      }`}
                  />
                </span>
                {isDropdownOpen && (
                  <div className="absolute left-0 bg-white text-gray-700 shadow-lg rounded-md w-40">
                    <a href="/about-us"><span className="block px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      About Us
                    </span></a>
                    <span className="block px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      Careers
                    </span>
                  </div>
                )}
              </div>
              {/* Loan Services Dropdown */}
              <div
                className="relative"
                onMouseEnter={toggleLoanDropdown}
                onMouseLeave={toggleLoanDropdown}
              >
                <span className="flex items-center text-gray-700 hover:text-orange-500 cursor-pointer">
                  Products
                  <IoIosArrowDown
                    className={`ml-1 transition-transform ${isLoanDropdownOpen ? "rotate-180" : ""
                      }`}
                  />
                </span>
                {isLoanDropdownOpen && (
                  <div className="absolute left-0 bg-white text-gray-700 shadow-lg rounded-md w-48">
                    {/* <a href="/personal-loan"><span className="block px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      Personal Loans
                    </span></a>
                    <a href="/business-loan"><span className="block px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      Business Loans
                    </span></a> */}
                  </div>
                )}
              </div>

              {/* Credit Report */}
              <span className="text-gray-700 hover:text-orange-500 cursor-pointer">
                Credit Report
              </span>

              {/* Channel Partner */}
              <a href="/channel-partner"><span className="text-gray-700 hover:text-orange-500 cursor-pointer">
                Channel Partner
              </span></a>

              {/* Loan Calculator */}
              <div
                className="relative"
                onMouseEnter={toggleLoanCalcDropdown}
                onMouseLeave={toggleLoanCalcDropdown}
              >
                <span className="flex items-center text-gray-700 hover:text-orange-500 cursor-pointer">
                  Calculators
                  <IoIosArrowDown
                    className={`ml-1 transition-transform ${isLoanCalculatorOpen ? "rotate-180" : ""
                      }`}
                  />
                </span>
                {isLoanCalculatorOpen && (
                  <div className="absolute left-0 bg-white text-gray-700 shadow-lg rounded-md w-48">
                    {/* <span className="block px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      Personal Loan Calculator
                    </span>
                    <span className="block px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      Business Loan Calculator
                    </span> */}
                  </div>
                )}
              </div>

            </div>
          </div>

          {/* Right Side: Call to Action */}
          <div className="hidden xl:flex 2xl:flex lg:flex items-center space-x-4">
            {/* <a href="/log-in"><span className="text-gray-700 hover:text-orange-500 font-medium cursor-pointer">
              Log In / Sign Up
            </span></a> */}
            <a href="/contact-us"><button className="text-white px-4 py-2 rounded-full bg-orange-500 hover:bg-orange-600 cursor-pointer MainButton">
              Contact Us
            </button></a>
          </div>

          {/* Mobile Menu Toggle */}
          <div className="lg:hidden xl:hidden 2xl:hidden ">
            <button
              onClick={toggleMenu}
              className="text-gray-700 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`lg:hidden bg-white border-t border-gray-200 transition-all duration-300 ease-in-out ${isMenuOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
            } overflow-hidden`}
        >
          <div className="flex flex-col space-y-2 p-4">
            <div>
              <span
                className="text-gray-700 hover:bg-gray-100 cursor-pointer flex justify-between items-center"
                onClick={() => toggleMobileDropdown("company")}
              >
                Company
                <IoIosArrowDown
                  className={`w-4 h-4 transition-transform ${mobileDropdown === "company" ? "rotate-180" : "rotate-0"
                    }`}
                />
              </span>
              {mobileDropdown === "company" && (
                <div className="ml-4 mt-2 space-y-1">
                  <a href="/about-us"><span className="block text-gray-700 hover:bg-gray-100 cursor-pointer">
                    About Us
                  </span></a>
                  <span className="block text-gray-700 hover:bg-gray-100 cursor-pointer">
                    Careers
                  </span>
                </div>
              )}
            </div>
            <div>
              <span
                className="text-gray-700 hover:bg-gray-100 cursor-pointer flex justify-between items-center"
                onClick={() => toggleMobileDropdown("loans")}
              >
                Products
                <IoIosArrowDown
                  className={`w-4 h-4 transition-transform ${mobileDropdown === "loans" ? "rotate-180" : "rotate-0"
                    }`}
                />
              </span>
              {mobileDropdown === "loans" && (
                <div className="ml-4 mt-2 space-y-1">
                  {/* <a href="/personal-loan"><span className="block text-gray-700 hover:bg-gray-100 cursor-pointer">
                    Personal Loans
                  </span></a>
                  <a href="/business-loan"><span className="block text-gray-700 hover:bg-gray-100 cursor-pointer">
                    Business Loans
                  </span></a> */}
                </div>
              )}
            </div>
            <span className="text-gray-700 hover:bg-gray-100 cursor-pointer">
              Credit Report
            </span>
            <a href="/channel-partner"><span className="text-gray-700 hover:bg-gray-100 cursor-pointer">
              Channel Partner
            </span></a>
            <div>
              <span
                className="text-gray-700 hover:bg-gray-100 cursor-pointer flex justify-between items-center"
                onClick={() => toggleMobileDropdown("calculator")}
              >
                Calculators
                <IoIosArrowDown
                  className={`w-4 h-4 transition-transform ${mobileDropdown === "calculator" ? "rotate-180" : "rotate-0"
                    }`}
                />
              </span>
              {mobileDropdown === "calculator" && (
                <div className="ml-4 mt-2 space-y-1">
                  {/* <a href="/personal-loan"><span className="block text-gray-700 hover:bg-gray-100 cursor-pointer">
                    Personal Loan Calculator
                  </span></a>
                  <span className="block text-gray-700 hover:bg-gray-100 cursor-pointer">
                    Business Loan Calculator
                  </span> */}
                </div>
              )}
            </div>

            {/* <a href="/log-in"><span className="text-gray-700 hover:bg-gray-100 cursor-pointer">
              Log In / Sign Up
            </span></a> */}
            <a href="/contact-us"><button className="text-white px-4 py-2 rounded-full bg-[#F2BD70] hover:bg-orange-600 cursor-pointer MainButton">
              Contact Us
            </button></a>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
