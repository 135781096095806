import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Contactus = () => {

    const [value, setValue] = useState()
    return (
        <div className='main-contanerr'>
            <div>
                <div>
                    <div>
                        <div className='w-[90%] mx-auto rounded-[30px] contact-us-info-main'>
                            <div className='px-[60px] py-[80px] xs:px-[20px] sm:px-[20px] rounded-[30px] mt-[22px] contact-us-info'>
                                <div className='w-[40%] xs:w-[100%] sm:w-[100%]'>
                                    <p className='text-[32px] font-[700] text-[#F2BD70]'>Regd. Office Address</p>
                                    <p className='text-[20px] font-[500] text-[#ffffff]'>Skyview Plaza, Suite 12B, Innovation Park, Tech City, New Mumbai - 400052, India (10 AM - 7 PM, Monday to Friday)</p>
                                </div>
                                <div className='w-[40%] xs:w-[100%] sm:w-[100%] mt-[40px]'>
                                    <p className='text-[32px] font-[700] text-[#F2BD70]'>Call on toll-free number</p>
                                    <a href='tel:+919712003010' className='text-[20px] font-[500] text-[#ffffff]'>+91 97120 03010</a><br />
                                    <a href='tel:+917600106841' className='text-[20px] font-[500] text-[#ffffff]'>+91 76001 06841</a>
                                </div>
                                <div className='w-[40%] xs:w-[100%] sm:w-[100%] mt-[40px]'>
                                    <p className='text-[32px] font-[700] text-[#F2BD70]'>Write to us</p>
                                    <a href='mailto:info@karmvedbharat.com' className='text-[20px] font-[500] text-[#ffffff]'>info@karmvedbharat.com</a><br />
                                    <a href='mailto:partner@karmvedbharat.com' className='text-[20px] font-[500] text-[#ffffff]'>partner@karmvedbharat.com</a><br />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div>
                        <div className='w-[90%] mx-auto bg-[#F3F3F3] px-[10px] py-[40px] rounded-[20px] mt-[80px]'>
                            <div>
                                <p className='text-[48px] xs:text-[40px] sm:text-[40px] font-[900] text-[#323232] text-center'>Get In Touch With Us!</p>
                                <p className='text-[18px] font-[500] text-[#323232] text-center'>Feel free to drop message and we'll get back to you..!</p>
                                <form>

                                    <div className='grid grid-cols-2 xs:grid-cols-1 sm:grid-cols-1 w-[80%] xs:w-[90%] sm:w-[90%] mt-[20px] mx-auto justify-center items-center gap-[20px]'>
                                        <div className=''>
                                            <label className='text-[#49454F] text-[16px] font-[500]'>Full Name</label><br />
                                            <input type='text' placeholder='Shah Kevan Rakeshbhai' className='w-[100%] xs:w-[100%] sm:w-[100%] bg-[#fff] px-[20px] py-[10px] rounded-[8px] mt-[5px] border input-box-shadow'></input>
                                        </div>
                                        <div>
                                            <label className='text-[#49454F] text-[16px] font-[500]'>Mobile Number</label><br />
                                            <PhoneInput
                                                country={'in'}
                                                value={value}
                                                onChange={setValue}
                                            />
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-2 xs:grid-cols-1 sm:grid-cols-1 w-[80%] xs:w-[90%] sm:w-[90%] mx-auto justify-center items-center mt-[20px] gap-[20px]'>
                                        <div className=''>
                                            <label className='text-[#49454F] text-[16px] font-[500]'>Email Address</label><br />
                                            <input type='text' placeholder='Enter your email ID' className='w-[100%]     xs:w-[100%] sm:w-[100%] bg-[#fff] px-[20px] py-[10px] rounded-[8px] mt-[5px] border input-box-shadow'></input>
                                        </div>
                                        <div className=''>
                                            <label className='text-[#49454F] text-[16px] font-[500]'>Subject</label><br />
                                            <input type='text' placeholder='Enter your Query Short' className='w-[100%]  xs:w-[100%] sm:w-[100%] bg-[#fff] px-[20px] py-[10px] rounded-[8px] mt-[5px] border input-box-shadow'></input>
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-1 xs:grid-cols-1 sm:grid-cols-1 w-[80%] xs:w-[90%] sm:w-[90%] mx-auto justify-center items-center gap-[20px]'>
                                        <div className='mt-[20px]'>
                                            <label className='text-[#49454F] text-[16px] font-[500]'>Message</label><br />
                                            <textarea rows={5} cols={10} placeholder='Write a message Here' className='w-[100%] xs:w-[100%] sm:w-[100%] bg-[#fff] px-[20px] py-[10px] rounded-[8px] mt-[5px] border input-box-shadow'></textarea>
                                        </div>
                                    </div>
                                    <div className='w-[80%] mx-auto'>
                                        <button type='submit' className='px-[20px] py-[10px] rounded-[40px] bg-[#F2BD70] text-[#563200] mt-[20px]'>Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contactus