import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 12px;
  @media (max-width: 600px) {
    gap:8px
  }
`;

const InputBox = styled.input`
  width: 50px;
  height: 50px;
  margin: 0 0px;
  text-align: center;
  font-size: 24px;
  border: 2px solid #ccc;
  border-radius: 8px;
  transition: border-color 0.3s;
  
  &:focus {
    border-color: #DC9731;
    outline: none;
    box-shadow: 0 0 10px rgba(106, 90, 205, 0.5);
  }

  @media (max-width: 600px) {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin: 0 0px;
  }
  @media (max-width: 350px) {
    width: 35px;
    height: 35px;
    font-size: 20px;
    margin: 0 0px;
  }
`;

const OTPInput = ({ length = 6, onComplete }) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (element, index) => {
    const value = element.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1); // Take only last digit
    setOtp(newOtp);

    // Move to next input box or call onComplete
    if (value && index < length - 1) {
      inputRefs.current[index + 1].focus();
    } else if (newOtp.every((digit) => digit !== "")) {
      onComplete(newOtp.join(""));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <Container>
      {otp.map((_, index) => (
        <InputBox
          key={index}
          type="text"
          maxLength="1"
          placeholder='0'
          value={otp[index]}
          onChange={(e) => handleChange(e.target, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (inputRefs.current[index] = el)}
        />
      ))}
    </Container>
  );
};

export default OTPInput;
